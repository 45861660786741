import React, { createContext } from 'react';
import propTypes from 'prop-types';

const ConfigContext = createContext({});

const defaultConfig = {
  env: process.env.REACT_APP_ENV.trimEnd() || '',
  appOrigin: process.env.REACT_APP_ORIGIN.trimEnd() || '',
  myschl: {
    origin: process.env.REACT_APP_MYSCHL_ORIGIN.trimEnd() || '',
    signInOrigin: process.env.REACT_APP_MYSCHL_SIGNIN_ORIGIN.trimEnd() || ''
  },
  tso: {
    origin: process.env.REACT_APP_TSO_ORIGIN.trimEnd() || '',
    demandware: process.env.REACT_APP_TSO_ORIGIN.trimEnd().concat('/on/demandware.store/Sites-tso-us-Site/default') || ''
  },
  paisURL: process.env.REACT_APP_PAIS_URL.trimEnd() || ''
};

const ConfigProvider = ({ value, children }) => 
  <ConfigContext.Provider value={value}>
    {children}
  </ConfigContext.Provider>;

ConfigProvider.propTypes = {
  value: propTypes.object,
  children: propTypes.node.isRequired
};

ConfigProvider.defaultProps = {
  value: defaultConfig
}

export { ConfigContext, ConfigProvider };