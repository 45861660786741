import React, { Component } from 'react'
import './CategoryPage.scss'
import FictionImage from 'images/fiction.png'
import NonFictionImage from 'images/nonfiction.png'
import ImageCTA from 'components/content/DiscoverForm/ImageCTA'
import * as dumbleData from 'DumbleData';

export class CategoryPage extends Component {
	componentDidMount() {
		dumbleData.setTitle('BookWizard:Discover:Category');
		dumbleData.trackPageLoad()
	}

	render() {
		const { values, handleChange } = this.props;

		let categoryOptions = Object.entries(values.categories).map(([key, value]) => 
			<ImageCTA
				image={key === 'fiction' ? FictionImage : NonFictionImage}  
				text={value.display_name}
				key={key}
				className="categoryPage__cta"
				selected={value.selected}
				handleClick={() => handleChange('categories', key)}
			/>
		)

		return (
			<div className="categoryPage">
					{categoryOptions}
			</div>
		)
	}
}

export default CategoryPage