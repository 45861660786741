import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import * as dumbleData from 'DumbleData';
import './ResultsPage.scss'
import Modal from './Modal';
import constructorio, { constructorPod, recommendationsConstructor } from 'utils/Constructorio.js';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { ConfigContext } from 'providers/ConfigContext';
import Cookies from 'js-cookie';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import Nav from 'components/structure/Nav/Nav.js';
import BookCard from 'components/content/BookCard/BookCard.js';
import BookALikeHeader from './BookALikeHeader/BookALikeHeader.js';
import LevelHeaderSearch from 'components/content/ResultsPage/LevelHeaderSearch/LevelHeaderSearch.js';
import DiscoveryHeaderFilters from 'components/content/ResultsPage/DiscoveryHeaderFilters/DiscoveryHeaderFilters.js';
import CollectionHeaderFilters from 'components/content/ResultsPage/CollectionHeaderFilters/CollectionHeaderFilters.js';
import OutlineButton from 'components/content/Button/OutlineButton.js';
import SolidButton from 'components/content/Button/SolidButton.js'
import ViewAllBar from 'components/content/ResultsPage/ViewAllBar/ViewAllBar.js';
import ViewAllCard from 'components/content/ResultsPage/ViewAllCard/ViewAllCard.js';
import Pagination from 'components/content/ResultsPage/Pagination/Pagination.js';
import ZeroResultsPage from './ZeroResultsPage/ZeroResultsPage.js';
import SaveToWishlistAlert from './SaveToWishlistAlert/SaveToWishlistAlert.js';
import { isWishlistEnabled } from 'utils/Wishlist';

const readingLevels = new Set(['acceleratedReaderLevel', 'draLevel', 'guidedReadingLevel', 'lexileLevel']);


export default function ResultsPage(props) {
	const configContext = useContext(ConfigContext);
	const tsoOrigin = configContext.tso.origin;
	const [searchResults, setSearchResults] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [browseFacets, setBrowseFacets] = useState([]);
	const [numResults, setNumResults] = useState(0);
	const [isFilteredResults, setIsFilteredResults] = useState(false);
	const [headerOffset, setHeaderOffset] = useState(98);
	const [navOffset, setNavOffset] = useState(70);
	const [hideOnScroll, setHideOnScroll] = useState(true)
	const [addToCartItem, setaddToCartItem] = useState(null);
	const [saveToWishlistItem, setSaveToWishlistItem] = useState({});
	const [saveAllToWishlist, setSaveAllToWishlist] = useState([]);
	const [wishlistAlert, setWishlistAlert] = useState(false);
	const [isLoadingResults, setIsLoadingResults] = useState(false);
	const [bookALikeItem, setBookALikeItem] = useState(null);
	const [openMultipleWishlistModal, setOpenMultipleWishlistModal] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [pages, setPages] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentInputPage, setCurrentInputPage] = useState(1);
	const [allSearchResults, setAllSearchResults]= useState([]);
	const [catalogName, setCatalogName] = useState('NTS');
	const [cartInfo, setCartInfo] = useState({});
	const [belowBudget, setBelowBudget] = useState(true);
	const [cnstTotalResults, setCnstTotalResults] = useState(null);


	const Wishlist_Queue = "Wishlist_Queue";

	// Numbers here based on Constructor and SFCC, and may change.
	const cartProductCap = 300;

	// Ref to store the timout ID of the saveToWishlist alert, in case the user closes it preemptively
	const alertTimeout = useRef(0)

	const {isSignedIn, setIsSignedIn, myScholasticModal, setMyScholasticModal, contractIdModal, setContractIdModal} = props

	const handleSearchQuery = useCallback((query) => {
		setIsLoadingResults(true)
		constructorio.search.getSearchResults(
			query,
			{
				filters: {
					'product-type': 'books/individual-titles'
				}
			}
		).then((data) => {
			dumbleData.setResults(data, query);
			let results = filterResults(data.response.results).slice(0,10);
			setSearchResults(results);
			setSearchQuery(query);
			setNumResults(calculateNumResults(results.length, data.response.total_num_results));
		}).catch((error) => {
			console.log(error);
		}).finally(() => {
			setIsLoadingResults(false);
		});
	}, [])

	useEffect(() => {
		dumbleData.setPageType('Results');
		dumbleData.setTitle('BookWizard:Results');
		dumbleData.trackPageLoad();

		if(props.location.search) {
			let query = new URLSearchParams(props.location.search).get("q")
			// If the user refreshes the page on a search, repopulates search data
			if (!window.dumbleData.search.type) {
				dumbleData.setSearchTypeInputKeyword('user-entered', query)
			}
			handleSearchQuery(query);
		} 
		
	}, [handleSearchQuery, props.location])

	const notFirstRun = React.useRef(false);

	useEffect(() => {

		const setupPagination = (results) => {
			setAllSearchResults(results);
			setNumResults(results.length);
			setPages(Math.ceil(results.length / itemsPerPage));
			setSearchResults(results.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
		}

		if(props.location.state && props.location.state.browseFacets) {
			setIsFilteredResults(true);
			dumbleData.setSearchType(props.location.state.flow);

			let facets = props.location.state.browseFacets;

			const fetchInitial = async() => {
				if ( notFirstRun.current ) return;
				let parameters;

				if(props.location.state.flow !== 'collection-flow') {
					parameters = {
						filters: {
							...(facets[0] !== '') && {[facets[0]]: facets[1]},
							'grade': facets[2],
							'super-genre': facets[3],
							'subject': facets[4],
							'genre': facets[5]
						}
					};
				// Collection flow filters optional 'total results' and 'max price' filters,
				// and also changes the order of the facets array
				} else {
					let maxPrice = (facets[2] !== '') ? `"-inf"-"${facets[2]}"`:'';
					parameters = {
						filters: {
							...(facets[3] !== '') && {[facets[3]]: facets[4]},
							'grade': facets[5],
							'super-genre': facets[6],
							'subject': facets[7],
							'language': facets[8],
							'genre': facets[9],
							...(maxPrice !== '') && {'price': maxPrice}
						},
						resultsPerPage: 20,
						page: 1
					}
					
				}

				const data = await constructorio.browse.getBrowseResults(
					'product-type',
					'books/individual-titles',
					parameters
				)
				setCnstTotalResults(data.response.total_num_results);
				let results = data.response.results;
				if(props.location.state.flow !== 'collection-flow') {
					results = filterResults(results);
				}

				// Do not display Out of Stock items if user chose that toggle
				if(props.location.state.flow === 'collection-flow' && props.location.state.collectionSettings[0] === false) {
					results = results.filter(item => !item.data.button.includes('out-of-stock'));
				}

				if(props.location.state.flow === 'collection-flow') {
					//dumbleData.setCollectionData(data, props.location.state.browseQuery);
					// Calculating the total price for the top navigation bar
					let total = 0;
					if(facets[1] !== '') {
						results.forEach(product => {
							total = total + (product.data.displayPrice * facets[1]);
						});
					} else {
						results.forEach(product => {
							total = total + (product.data.displayPrice);
						});
					}
					// Checking to see if we need to cap results based on user budget
					var budgetCapped = false;
					if(props.location.state.browseQuery.budget !== '') {
						let budget = props.location.state.browseQuery.budget;
						if(total > budget) {
							if(facets[1] !== '') {
								do {
									total = total - (results[results.length-1].data.displayPrice * facets[1]);
									results.pop();
								} while(total > budget);
							} else {
								do {
									total = total - (results[results.length-1].data.displayPrice);
									results.pop();
								} while(total > budget);
							}
							// this flag is to let us know we should setNumResults = results.length later.
							budgetCapped = true;
						} else {
							setIsLoadingResults(true);
							fetchCollections(data.response.total_num_results);
							return;
						}
					}
					const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					});
					setTotalPrice(formatter.format(total));

					var bookQtyCapped = false;
					if(facets[0] !== '' && facets[0] <= 20) {
						bookQtyCapped = true;
					} else if ( facets[0] !== '' && facets[0] > 20 && data.response.total_num_results > 20) {
						setIsLoadingResults(true);
						fetchCollections(data.response.total_num_results);
						return;
					}
				}

				// We only want to cut off search results to 10 products on discover.
				if(props.location.state.flow !== 'collection-flow') {
					setSearchResults(results.slice(0,10));
				}

				//setSearchResults(results);
				setBrowseFacets(facets);

				if(props.location.state.flow !== 'collection-flow') {
					setNumResults(calculateNumResults(results.length, cnstTotalResults))
				} else {
					let totalResults = cnstTotalResults;
					// If we're limiting the # of based on user budget or # of requested books,
					// just display the # of results.
					bookQtyCapped = false;
					if(facets[0] !== '') {
						if ( facets[0] <= results.length ) {
							results = results.slice(0, facets[0]);
							bookQtyCapped = true;
						}
						setTotalPrice(calculateTotalPrice(results, facets[1]));
					}
					if ( budgetCapped || bookQtyCapped ) {
						totalResults = results.length;
					// Otherwise, total results can be at max the # of products we can pull from construtor.
					} 
	
					let products = results.map(c => ({"pid" : c.data.id + '-tso-us', "qty": facets[1], "spCatalogName": catalogName}) );
					setSaveAllToWishlist(products);
					setAllSearchResults(results);
					setNumResults(totalResults);
					setupPagination(results);
	
					dumbleData.setCollectionData(null, props.location.state.browseQuery);
				}
				

			}

			const fetchCollections = async (total_num_results) => {
				let parameters;
				
				if(props.location.state.flow !== 'collection-flow') {
					parameters = {
						filters: {
							...(facets[0] !== '') && {[facets[0]]: facets[1]},
							'grade': facets[2],
							'super-genre': facets[3],
							'subject': facets[4],
							'genre': facets[5]
						}
					};
				// Collection flow filters optional 'total results' and 'max price' filters,
				// and also changes the order of the facets array
				} else {
					let maxPrice = (facets[2] !== '') ? `"-inf"-"${facets[2]}"`:'';
					parameters = {
						filters: {
							...(facets[3] !== '') && {[facets[3]]: facets[4]},
							'grade': facets[5],
							'super-genre': facets[6],
							'subject': facets[7],
							'language': facets[8],
							'genre': facets[9],
							...(maxPrice !== '') && {'price': maxPrice}
						},
						resultsPerPage: 200,
					}
		
					
				}
			
				Promise.all(
					[...Array(Math.ceil(total_num_results/200))].map((_, i) => {
					
						 return constructorio.browse.getBrowseResults(
							'product-type',
							'books/individual-titles',
							{
								...parameters,
								page: i + 1
							}
						)
					})
				)
				.then((data) => {
					let results = [];
					data.map(m => results.push(...m.response.results))
					results = filterResults(results);
					setAllSearchResults(results);
			
				// Do not display Out of Stock items if user chose that toggle
				if(props.location.state.flow === 'collection-flow' && props.location.state.collectionSettings[0] === false) {
					results = results.filter(item => !item.data.button.includes('out-of-stock'));
				}

				// Collection-flow specific logic for total price and budget capping.
				if(props.location.state.flow === 'collection-flow') {
					//dumbleData.setCollectionData(data, props.location.state.browseQuery);
					// Calculating the total price for the top navigation bar
					let total = 0;
					if(facets[1] !== '') {
						results.forEach(product => {
							total = total + (product.data.displayPrice * facets[1]);
						});
					} else {
						results.forEach(product => {
							total = total + (product.data.displayPrice);
						});
					}
					// Checking to see if we need to cap results based on user budget
					var budgetCapped = false;
					if(props.location.state.browseQuery.budget !== '') {
						let budget = props.location.state.browseQuery.budget;
						if(total > budget) {
							if(facets[1] !== '') {
								do {
									total = total - (results[results.length-1].data.displayPrice * facets[1]);
									results.pop();
								} while(total > budget);
							} else {
								do {
									total = total - (results[results.length-1].data.displayPrice);
									results.pop();
								} while(total > budget);
							}
							// this flag is to let us know we should setNumResults = results.length later.
							budgetCapped = true;
							setBelowBudget(false)
						}
					}
					const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					});
					setTotalPrice(formatter.format(total));

					
				}
				
				// We only want to cut off search results to 10 products on discover.
				if(props.location.state.flow !== 'collection-flow') {
					setSearchResults(results.slice(0,10));
				}

				//setSearchResults(results);
				setBrowseFacets(facets);

				if(props.location.state.flow !== 'collection-flow') {
					setNumResults(calculateNumResults(results.length, total_num_results))
				} else {
					let totalResults = total_num_results;
					// If we're limiting the # of based on user budget or # of requested books,
					// just display the # of results.
					var bookQtyCapped = false;
					if(facets[0] !== '') {

						if ( facets[0] < total_num_results ) {
							results = results.slice(0, facets[0]);
							bookQtyCapped = true;
						}
						setTotalPrice(calculateTotalPrice(results, facets[1]));
					}
					if ( budgetCapped || bookQtyCapped ) {
						totalResults = results.length;
					// Otherwise, total results can be at max the # of products we can pull from construtor.
					} 
	
					let products = results.map(c => ({"pid" : c.data.id + '-tso-us', "qty": facets[1], "spCatalogName": catalogName}) );
					setSaveAllToWishlist(products);
	
					setNumResults(totalResults);
					setupPagination(results);
	
					dumbleData.setCollectionData(null, props.location.state.browseQuery);
				}



				})
				.catch(error => console.log(error))
				.finally(() => {
					setIsLoadingResults(false)
				});
			
				
			}

			fetchInitial();
			notFirstRun.current = true;
		}
	}, [belowBudget, props.location, allSearchResults, cnstTotalResults, notFirstRun, catalogName, currentPage, itemsPerPage])

	// Effect for fetching Book-a-Like recommendations when a Book-a-Like button is clicked
	useEffect(() => {
		/** Accepts a list of Constructor recommendations and filters them based on 2 parameters:
		 *  1. Filters out any books that are not individual titles (collections, etc.)
		 *  2. Removes any books that do not have any reading level information (lexile, guided, etc.)
		 */
		const filterRecommendations = (recs) => {
			return recs.filter((book) => {
				let productTypeFacet, productType, hasReadingLevels;
				
					productTypeFacet = book.data.facets.find(({name}) => name === 'product-type')
					productType = productTypeFacet.values[1]
					hasReadingLevels = book.data.facets.some(({name}) => readingLevels.has(name))
				
				
				return productType === 'books/individual-titles' && hasReadingLevels
			})
		}
		// calls Constructor using the recommendations module and populates the searchResults state variable
		const fetchRecommendations = async () => {
			const data = await recommendationsConstructor.recommendations.getRecommendations(constructorPod, {
				itemIds: bookALikeItem.data.id,
				numResults: 20
			})
			let results = filterRecommendations(data.response.results).slice(0,10)
			setSearchResults(results)
			dumbleData.trackPageLoad();
			dumbleData.trackBookALikeSearch(results.length, bookALikeItem.value)
		}
		if (bookALikeItem) {
			fetchRecommendations()
		}
	}, [bookALikeItem])

	useEffect(() => {
		let isMobile = window.matchMedia('(max-width: 719px)').matches;
		if(isMobile && navOffset === 70){
			setNavOffset(90);
		} else if(!isMobile && navOffset === 90){
			setNavOffset(70);
		}
	}, [navOffset, headerOffset])

	const updatePaginationResults = (newPageNum, ipp, newResults) => {
		let pageNum = newPageNum || currentPage;
		let productPerPage = ipp || itemsPerPage;
		let results = newResults || allSearchResults;
		setSearchResults(results.slice((pageNum - 1) * productPerPage, pageNum * productPerPage));
	}

	const updatePagination = (e) => {
		let newPageNum = parseInt(e.target.value) || '';
		if ( newPageNum > 0 && newPageNum <= pages ) {
			setCurrentPage(newPageNum);
			setCurrentInputPage(newPageNum);
			updatePaginationResults(newPageNum);
		} else if (newPageNum === '') {
			setCurrentInputPage(newPageNum);
		}
	}

	const prev = () => {
		let newPageNum = parseInt(currentPage) - 1;
		if ( newPageNum > 0 ) {
			setCurrentPage(newPageNum);
			setCurrentInputPage(newPageNum);
			updatePaginationResults(newPageNum);
		}
	}

	const next = () => {
		let newPageNum = parseInt(currentPage) + 1;
		if ( newPageNum <= pages ) {
			setCurrentPage(newPageNum);
			setCurrentInputPage(newPageNum);
			updatePaginationResults(newPageNum);
		}
	}

	const handleItemsChange = (e) => {
		window.scrollTo(0,0);
		setTimeout(function() {
			setCurrentPage(1);
			setCurrentInputPage(1);
			setItemsPerPage(e.target.value);
			setPages(Math.ceil(allSearchResults.length / e.target.value));
			updatePaginationResults(1, e.target.value);
		}, 100);
	}

	const updateLocalStorageWishlist = (isbn13) => {
		let wishlistItems = JSON.parse(
			localStorage.getItem(Wishlist_Queue)
		);
		// add item to local storage wishlist if it's not in it already
		if (wishlistItems && wishlistItems.length > 0) {
			if (!wishlistItems.includes(isbn13)) {
				wishlistItems.push(isbn13);
			}
		} else {
			// create local storage wishlist if it doesn't exist
			wishlistItems = [];
			wishlistItems.push(isbn13);
		}
		localStorage.setItem(
			Wishlist_Queue,
			JSON.stringify(wishlistItems)
		);
	};


	/** When the Book-a-Like button is clicked, get the data of the book from the
	 *  search results, and set the bookALikeItem state variable
	 */
	const handleBookALikeClick = (isbn) => {
		let bookALikeResult = searchResults.find(book => book.data.id === isbn)
		window.scrollTo(0,0)
		setTimeout(function() {
			setBookALikeItem(bookALikeResult)
		}, 100);
		window._satellite.track('book-a-like', {
			productId: bookALikeResult
		});
	}

	/** Filters out any results that do not have reading level information,
	 *  which is located in the facets property
	 */
	const filterResults = (data) => {
		return data.filter(book => {
			return book.data.facets?.some(({name}) => readingLevels.has(name))
		})
	}

	/** If the number of filtered results is 10, but the total number
	 *  of results returned from constructor is greater than 10, return
	 *  totalNumResults. Otherwise, return resultsLength
	 */
	const calculateNumResults = (resultsLength, totalNumResults) => {
		if (resultsLength === 10 && totalNumResults > 10) {
			return totalNumResults
		}
		return resultsLength
	}

	const calculateTotalPrice = (results, quantityPerItem) => {
		let total = 0;
		if(quantityPerItem) {
			results.forEach(product => {
			    total = total + (product.data.displayPrice * quantityPerItem);
			});
		} else {
			results.forEach(product => {
			    total = total + (product.data.displayPrice);
			});
		}
		const formatter = new Intl.NumberFormat('en-US', {
		  style: 'currency',
		  currency: 'USD',
		});
		return formatter.format(total);
	}

	/** Wrapping this in a callback so it can be passed down and
	 *  not cause any effects from running unnecessarily
	 */
	const handleHeaderUpdate = useCallback((headerHeight) => {
		setHeaderOffset(headerHeight);
	}, [])

	/** Function that will remove any decimal codes, such as
	 *  &#233, which is é
	 */
	const decodeText = (text) => {
		let textArea = document.createElement("textarea");
		textArea.innerHTML = text;
		return textArea.value;
	}

	const inWishlistHelper = (isbn) => {
		/** removing 'view wishlist' functionality **/
		return false;
	};


	useEffect(() => {
		if(openMultipleWishlistModal){
			document.body.style.overflow = 'hidden';
		}else{
			document.body.style.overflow = 'unset';
		}
	}, [openMultipleWishlistModal])


	/** When the save to wishlist button is clicked and the user is signed in,
	 *  add the book to their wishlist. If the user is not signed in, display
	 *  the sign-in modal. After the user signs in, add the book to their wishlist.
	 */
	const handleWishlistClick = (productObject) => {
		dumbleData.setProductInfo(productObject,'wishlist');
		dumbleData.trackAddToWishlist();
		window._satellite.track('save-to-wishlist', {
			productId: productObject.isbn
		});
		let sps_ud = Cookies.get('SPS_UD');
		let schl = Cookies.get("SCHL")
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (!sps_ud && !schl) {
			props.setIsSignedIn(false);
			if (partnerCookie && (partnerCookie === 'FACE' || partnerCookie === 'LP')) {
				props.setContractIdModal({ isOpen: true, openedFrom: 'wishlist'});
			} else {
				props.setMyScholasticModal({ isOpen: true, openedFrom: 'wishlist'});
			}
		} else {
			setSaveToWishlistItem(productObject)
			setOpenMultipleWishlistModal(true);
		}
	}

	const handleWishlistAllClick = (productObject) => {
		let sps_ud = Cookies.get('SPS_UD');
		let schl = Cookies.get("SCHL")
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (!sps_ud && !schl) {
			props.setIsSignedIn(false);
			if (partnerCookie && (partnerCookie === 'FACE' || partnerCookie === 'LP')) {
				props.setContractIdModal({ isOpen: true, openedFrom: 'wishlist'});
			} else {
				props.setMyScholasticModal({ isOpen: true, openedFrom: 'wishlist'});
			}
		} else {
			let quantity = props.location.state.browseQuery.quantity || 1;
			let products = productObject.map(c => ({"pid" : c.data.id + '-tso-us', "qty": quantity, "spCatalogName": catalogName}) );
			let sproducts = productObject.map(c => ({"productId" : c.data.id, "quantity": quantity}) );
			setSaveAllToWishlist(products);
			setOpenMultipleWishlistModal(true);
			window._satellite.track('save-all-to-wishlist', {
				products: sproducts
			});

			
		}
	}

	const callbackWishlist = useCallback((event) => {

		if (event && event.origin === tsoOrigin) {
			try {
        		var responseMessage  = (
					typeof event.data === 'object' &&
					!Array.isArray(event.data) &&
					event.data !== null
				) ? event.data : JSON.parse(event.data);

				if (responseMessage.message  === "multipleItems-wishList-modal") {
					let quantity = props.location.state.browseQuery.quantity || 1;
					let products = allSearchResults.map(c => ({"pid" : c.data.id + '-tso-us', "qty": quantity, "spCatalogName": catalogName}) );
					let ifrm = document.getElementById('wishlist-iframe');
					ifrm.contentWindow.postMessage({
						message: "add-multiple-product-data",
						selector: "#productDetails",
						dataItems: JSON.stringify(products)
					}, tsoOrigin)
				} 


				if (responseMessage.status === 'CLOSE' || responseMessage.status === 'ERROR' ) {
					setOpenMultipleWishlistModal(false);
					setSaveToWishlistItem({});
				}
				if (responseMessage.status === 'AUTHENTICATE_USER' ) {
					setOpenMultipleWishlistModal(false);
					props.setMyScholasticModal({ isOpen: true, openedFrom: 'wishlist'})
				}
				if (responseMessage.status === 'SUCCESS') {
					if(saveToWishlistItem.isbn){
						updateLocalStorageWishlist(saveToWishlistItem.isbn);
						setSaveToWishlistItem({})
					}
					setOpenMultipleWishlistModal(false);
				}
				if(responseMessage.status === 'REDIRECT'){
					setOpenMultipleWishlistModal(false);
					if(saveToWishlistItem.isbn){
						updateLocalStorageWishlist(saveToWishlistItem.isbn);
						setSaveToWishlistItem({})
					}
					window.open(responseMessage.data.wishlistUrl, '_blank');
				}
			} catch(error) {
				console.log(error);
			}		
		}
	},[allSearchResults, saveToWishlistItem.isbn, props, tsoOrigin, catalogName]);

	useEffect(()=>{
		if(openMultipleWishlistModal){
			window.addEventListener("message", callbackWishlist);
		}else{
			return () => {
				window.removeEventListener("message", callbackWishlist);
			}
		}
		
	},[openMultipleWishlistModal, callbackWishlist]);

	useEffect(() => {
		let partnerCookie = Cookies.get('SPS_PTNR');
		let catalog = '';
		switch (partnerCookie) {
			case 'TSO':
				catalog = 'NTS';
				break;
			case 'LP':
				catalog = 'VDK';
				break;
			case 'FACE':
				catalog = 'VDL';
				break;
			case 'NYS':
				catalog = 'NYS';
				break;
			case 'COS':
				catalog = 'COS';
				break;
			case 'RIF':
			case 'NAT':
				catalog = 'NAT';
				break;
			case 'ROR':
				catalog = 'ROR';
				break;
			default:
				catalog = 'NTS';
				break;
		}
		setCatalogName(catalog);
	}, []);




	const renderBookCards = () => {
		// If there are results, display them in book cards
		if (numResults > 0) {
			return searchResults.map((book, index) =>
				<BookCard
					key={book.data.id}
					isbn={book.data.id}
					img={book.data.image_url}
					title={book.value}
					format={book.data.displayFormat}
					grade={book.data.displayGrade}
					listPrice={book.data.listPrice}
					onSale={book.data.on_sale}
					displayPrice={book.data.displayPrice}
					author={book.data.author ? decodeText(book.data.author) : ''}
					illustrator={book.data.illustrator ? decodeText(book.data.illustrator) : ''}
					url={tsoOrigin + book.data.url}
					quantity={browseFacets[1]}
					facets={book.data.facets ? book.data.facets : ''}
					handleAddToCart={handleAddToCart}
					addToCartButtonLabel={JSON.parse(book.data.button).label.trim().toUpperCase()}
					inWishlist={inWishlistHelper(book.data.id)}
					productId={book.data.mdmProductId}
					handleWishlistClick={handleWishlistClick}
					handleBookALikeClick={props.location.state.flow !== 'collection-flow' ? handleBookALikeClick: ''}
					handleDeleteBookCard={props.location.state.flow === 'collection-flow' ? handleDeleteBookCard : ''}
				/>
			);
		// If there are no results after calling constructor, show a custom page
		} else if (!isLoadingResults) {
			return <ZeroResultsPage
						history={props.history}
						flow={props.location.state.flow}

					/>
		}
		// Show nothing while the page is still loading results
		return null;
	}

	const renderPagination = () => {
		if (numResults > 0) {
			return <Pagination>
				<div className="p1">
					{ pages > 1 ? <IconButton aria-label="previous" className={`pPrev ${currentPage === 1 ? 'hidden' : ''}`} onClick={prev}><KeyboardArrowLeftIcon/></IconButton> : ''} 
					<input type="text" value={currentInputPage} onChange={(e) => updatePagination(e)} /> of {pages} 
					{pages > 1 ? <IconButton aria-label="next" className={`pNext ${currentPage === pages ? 'hidden' : ''}`} onClick={next}><KeyboardArrowRightIcon/></IconButton> : ''}
				</div> 	
				<div className="p2">
					<span className="resultsCount">
						{currentPage === 1 ? '1' : (currentPage - 1) * itemsPerPage + 1 } - {(currentPage * itemsPerPage) > numResults ? numResults : (currentPage * itemsPerPage)} out of {numResults}
					</span>
					<span className="itemsPerPage">
						Items per page
					</span>
					<FormControl>
						<Select
							label="Items Per Page"
							value={itemsPerPage}
							onChange={handleItemsChange}
							displayEmpty
							IconComponent={KeyboardArrowDownIcon}
						>
							<MenuItem value={20}>20</MenuItem>
							<MenuItem value={40}>40</MenuItem>
							<MenuItem value={80}>80</MenuItem>
						</Select>
					</FormControl>
				</div>
			</Pagination>

		// If there are no results after calling constructor, show a custom page
		} else if (!isLoadingResults) {
			return null
		}
		// Show nothing while the page is still loading results
		return null;
	}

	const handleAddToCart = (itemProps) => {
		dumbleData.setProductInfo(itemProps, 'product');
		dumbleData.trackAddToCart();
		let q = 1;
		if ( props.location.state.flow === 'collection-flow' ) {
			q = props.location.state.browseQuery.quantity || 1;
		}
		window._satellite.track('add-to-cart', {
			productId: itemProps.isbn,
			quantity: q
		});
		setaddToCartItem(itemProps);
	}

	const handleAddAllToCart = (itemProps) => {
		let products = [];
		itemProps.forEach(p => {
			products.push({productId: p.data.id, quantity: props.location.state.browseQuery.quantity || 1})
		});
		window._satellite.track('add-all-to-cart', {
			products 
		})
		setaddToCartItem(itemProps);
	}

	const resetCart = () => {
		setaddToCartItem([])
	}

	const handleDeleteBookCard = (itemProps) => {
		// Filter search results and remove the ID user wanted to delete
		let newResults = allSearchResults;
		newResults = newResults.filter(item => item.data.id !== itemProps);
		setNumResults(numResults-1);
		setAllSearchResults(newResults);
		setTotalPrice(calculateTotalPrice(newResults, props.location.state.browseFacets[1]));
		setPages(Math.ceil(newResults.length / itemsPerPage));
		updatePaginationResults(currentPage, null, newResults);
	}

	const generateTSOLinkMessage = () => {
		let tsoLink;
		let store = 'teacher';
		let heading = 'TEACHER';
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie && partnerCookie !== 'TSO') {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
			store = partnerCookie.toLowerCase();
			heading = `${partnerCookie.toUpperCase()} MEMBER`;
		}

		if(searchQuery) {
			let queryParams = searchQuery.split(" ").join("%20");
			tsoLink=`${tsoOrigin}/teachers-ecommerce/${store}/search-results.html?search=1&prefilter=&text=${queryParams}&f.product-type=books%2Findividual-titles`;
		} else if (browseFacets.length && props.location.state.flow !== 'collection-flow') {
			let readingLevelParams = browseFacets[0] !== "" ? `&f.${browseFacets[0]}=` + browseFacets[1] : '';
			let gradeParams = browseFacets[2].length ? '&f.grade=' + browseFacets[2].join('&f.grade=') : '';
			let superGenreParams = browseFacets[3].length ? '&f.super-genre=' + browseFacets[3].join('&f.super-genre=') : '';
			let subjectParams = browseFacets[4].length ? '&f.subject=' + browseFacets[4].join('&f.subject=') : '';
			let genreParams = browseFacets[5].length ? '&f.genre=' + browseFacets[5].join('&f.genre=') : '';
			let facetParams = ''.concat(readingLevelParams, gradeParams, superGenreParams, subjectParams, genreParams);
			tsoLink=`${tsoOrigin}/teachers-ecommerce/${store}/shops/book-wizard.html?p=1&n=20${facetParams}`;
		}
		return (
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={tsoLink}>
				{`VIEW ALL IN THE ${heading} STORE`}
			</a>
		)
	}

	const renderViewAllBar = () => {
		// Collection flow contains add all to wishlist & add all to cart buttons.
		if(props.location.state.flow === 'collection-flow') {
			let store = 'tso';
			let partnerCookie = Cookies.get('SPS_PTNR');
			if (partnerCookie) {
				if (partnerCookie === 'NAT') partnerCookie = 'RIF';
				store = partnerCookie.toLowerCase();
			}
			let searchResultsCount = numResults;
			let resultsMessage;
			(searchResultsCount > 1 || searchResultsCount === 0) ? resultsMessage = `${searchResultsCount} RESULTS` : resultsMessage = `1 RESULT`;
			let cartNum = cartInfo.totalCartItems || 0;
			let OOScount = 0;
			let addAllToCart = allSearchResults;
			if(props.location.state.collectionSettings[0] === '') {
				addAllToCart = allSearchResults.filter(item => !item.data.button.includes('out-of-stock'));
				OOScount = searchResultsCount - addAllToCart.length;
			}
	

			return (
				<ViewAllBar isBuildACollection={true}>
					<div><span className="viewallbar--results">{resultsMessage}</span><span className="viewallbar--total">TOTAL: {totalPrice}</span></div>
					<div>
						{ (searchResultsCount - OOScount) + cartNum > cartProductCap && <div className='viewallbar--full'>Your shopping cart is almost full. Please complete your purchase and begin a new order to continue shopping.</div>}
						{ isWishlistEnabled(store) ? 
						<OutlineButton
							text="SAVE ALL TO WISHLIST"
							className="viewallbar--addButton"
							handleClick={() => handleWishlistAllClick(allSearchResults)}
						/> : null }
						{(searchResultsCount - OOScount) + cartNum <= cartProductCap && <SolidButton
							text="ADD ALL TO CART"
							className="viewallbar--wishlistButton"
							handleClick={() => handleAddAllToCart(addAllToCart)}
						/>}
						<div className="viewallbar--loader"></div>
					</div>
				</ViewAllBar>
				)
			}
		else {
			let searchResultsCount = numResults;
			let resultsMessage;
			let tsoLinkMessage;
			if(searchResultsCount > 10 && (searchQuery || browseFacets)){
				resultsMessage = "10+ RESULTS"
				tsoLinkMessage = generateTSOLinkMessage();
			} else {
				resultsMessage = `${searchResultsCount} RESULT${searchResultsCount === 1 ? '' : 'S'}`;
			}
			return (
				<ViewAllBar>
					<span>{resultsMessage}</span>{tsoLinkMessage}
				</ViewAllBar>
				)
		}
	}

	const renderViewAllCard = () => {
		let searchResultsCount = numResults;
		let tsoLinkMessage;

		if(!bookALikeItem && searchResultsCount > 10 && (searchQuery || browseFacets)){
			tsoLinkMessage = generateTSOLinkMessage(searchResultsCount, searchQuery);
			return (
				<ViewAllCard>
					{tsoLinkMessage}
				</ViewAllCard>
			)
		}
		return null;
	}

	const renderHeader = () => {
		if (bookALikeItem) {
			return (
				<BookALikeHeader
					bookALikeItem={bookALikeItem}
					hideOnScroll={hideOnScroll}
					handleHeaderUpdate={handleHeaderUpdate}
					handleAddToCart={handleAddToCart}
					inWishlist={inWishlistHelper(bookALikeItem.data.id)}
					handleWishlistClick={handleWishlistClick}
					decodeText={decodeText}
					headerOffset={headerOffset}
				/>
			)
		} else if(isFilteredResults){
			if (props.location.state && props.location.state.flow) {
				if (props.location.state.flow === 'discovery-flow') {
					return(
						<DiscoveryHeaderFilters
							state={props.location.state.browseQuery}
							numResults={numResults}
							history={props.history}
							viewAllBar={renderViewAllBar()}
							hideOnScroll={hideOnScroll}
							handleHeaderUpdate={handleHeaderUpdate}
							headerOffset={headerOffset}
						/>
					);
				}  else if (props.location.state.flow === 'collection-flow') {
					return(
						<CollectionHeaderFilters
							state={props.location.state.browseQuery}
							numResults={numResults}
							history={props.history}
							viewAllBar={renderViewAllBar()}
							hideOnScroll={hideOnScroll}
							collectionSettings={props.location.state.collectionSettings}
							handleHeaderUpdate={handleHeaderUpdate}
							headerOffset={headerOffset}
						/>
					);
				}
			}

		} else if(props.location.search){
			return(
				<LevelHeaderSearch
					viewAllBar={renderViewAllBar()}
					hideOnScroll={hideOnScroll}
					handleHeaderUpdate={handleHeaderUpdate}
					levelABookSearchQuery={searchQuery}
					headerOffset={headerOffset}
				/>
			);
		}
	}

	/* library to track scroll */
	useScrollPosition(
		({ prevPos, currPos }) => {
			const isShow = currPos.y > -5
			let scrolledDownHeight = document.querySelector('#viewAllBar').clientHeight + document.querySelector('.resultsPage').clientHeight + document.querySelector('#globalfooter').clientHeight;
			if ( scrolledDownHeight > window.innerHeight && 720 < window.innerWidth ) {
				if (isShow !== hideOnScroll) {
					setHideOnScroll(isShow)
				}
			}	
		}, [hideOnScroll], false, false, 50 
	);
	
		
	if(props.location.search || (props.location.state && props.location.state.browseQuery)){
		return (
			<React.Fragment>
				<Nav
					addToCartItem={addToCartItem}
					resetCart={resetCart}
					hideOnScroll={hideOnScroll}
					isSignedIn={isSignedIn}
					setIsSignedIn={setIsSignedIn}
					myScholasticModal={myScholasticModal}
					setMyScholasticModal={setMyScholasticModal}
					contractIdModal={contractIdModal}
					setContractIdModal={setContractIdModal}
					d={props.location.state.browseQuery}
					handleCartChange={setCartInfo}
				/>

				<SaveToWishlistAlert
					hiddenClass={!wishlistAlert ? "hidden" : ""}
					product={saveToWishlistItem}
					hideOnScroll={hideOnScroll}
					handleAlertClose={() => {
						clearTimeout(alertTimeout.current)
						setWishlistAlert(false)
					}} />
				<div className="resultsPage" style={{'marginTop': `${hideOnScroll ? headerOffset + navOffset : navOffset}px`}}>
					{renderHeader()}
					<div className="grid-layout">
						{renderBookCards()}
						{props.location.state.flow !== 'collection-flow' ? renderViewAllCard() : renderPagination()}
					</div>
				</div>
				{openMultipleWishlistModal &&(
					<Modal handleModal= {()=>{setOpenMultipleWishlistModal(!openMultipleWishlistModal)}}>
						{saveAllToWishlist.length > 0 && Object.keys(saveToWishlistItem).length === 0 ? <iframe id="wishlist-iframe" src= {`${tsoOrigin}/on/demandware.store/Sites-tso-us-Site/default/Wishlist-AddItemToWishlist?multipleItems=true&totalNumberOfItems=${saveAllToWishlist.length}`} style={{border: 'none', overflow: 'hidden'}} title="Multiple Wishlists" className="iframe"/> : 
						<iframe id="wishlist-iframe" src= {`${tsoOrigin}/on/demandware.store/Sites-tso-us-Site/default/Wishlist-AddItemToWishlist?pid=${saveToWishlistItem.isbn}-tso-us&spCatalogName=${catalogName}&quantity=${props.location.state.flow === 'collection-flow' ? props.location.state.browseQuery.quantity || 1 : 1}`} title="Multiple Wishlists" className="iframe"/>}
					</Modal>
				)}
			</React.Fragment>
		)
	}
	/* if user navigated to /results with no queries, redirect to landing page */
	props.history.push({pathname: '/'});
	return null;
}