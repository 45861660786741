import React, { Component } from 'react';
import './Pagination.scss';

export class Pagination extends Component {

	render() {
		return (
			<div className="pagination">
				{this.props.children}
			</div>
		)
	}
}

export default Pagination
