import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './LevelHeaderSearch.scss';
import ResultsHeader from 'components/content/ResultsPage/ResultsHeader/ResultsHeader.js';
import magnifyingGlass from 'images/magnifying-glass-icon.svg';
import magnifyingGlassRed from 'images/magnifying-glass-icon__red.svg';
import * as dumbleData from 'DumbleData';

function LevelHeaderSearch (props) {

	let { levelABookSearchQuery  } = props;
	const [searchQuery, setSearchQuery] = useState(levelABookSearchQuery || '');
	/* ref to bind constructor-io autosuggest to search input */
	const inputRef = useRef(null);

	/* SB-19923: TYPEAHEAD DISABLED CONTINGENT ON CONSTRUCTOR-IO DEPENDENCY */
	// let autoComplete = null;
	// useEffect(() => {
	// 	/* bind constructor-io autosuggest methods after script-load */
	// 	if(window.constructorio_default_options){
	// 		// eslint-disable-next-line
	// 		autoComplete = new window.ConstructorioAutocomplete(
	// 			inputRef.current, 
	// 			window.constructorio_default_options.headerAutocompleteOptions
	// 			)
	// 		autoComplete.onSelect = onSuggestionSelect;
	// 	}
	// }, [window.constructorio_default_options]);

	function handleChange(event) {
		setSearchQuery(event.target.value);
	}
	
	function handleSubmit(event) {
		event.preventDefault();
		props.history.push({
			pathname: '/results',
			search: '?search&q=' + searchQuery,
			state: {
				flow: 'discovery-flow'
			}
		});
		dumbleData.setSearchTypeInputKeyword('user-entered', searchQuery);
		dumbleData.trackResultspageSearch();
	}

	/* handler for when user selects from constructor-io suggestions */
	/* SB-19923: TYPEAHEAD DISABLED CONTINGENT ON CONSTRUCTOR-IO DEPENDENCY */
	// function onSuggestionSelect(event) {
	// 	let selectedSearchQuery = autoComplete.suggestions[event].value;
	// 	if(selectedSearchQuery){
	// 		if(selectedSearchQuery){
	// 			props.history.push({
	// 				pathname: '/results',
	// 				search: '?search&q=' + selectedSearchQuery
	// 			});
	// 		}
	// 		inputRef.current.value = selectedSearchQuery;
	// 	}
	// 	dumbleData.setSearchTypeInputKeyword('type-ahead', selectedSearchQuery, autoComplete.currentValue);
	// 	dumbleData.trackResultspageSearch();
	// }

	return (
		<ResultsHeader hideOnScroll={props.hideOnScroll} handleHeaderUpdate={props.handleHeaderUpdate} headerOffset={props.headerOffset}>
			<div className="levelHeaderSearch grid-layout">
				<form className="levelHeaderSearch__form" onSubmit={handleSubmit}>
					<div className="levelHeaderSearch__input--wrapper">
						<input 
							ref={inputRef} 
							className="levelHeaderSearch__input" 
							type="text" 
							onChange={handleChange} 
							placeholder="Enter Title, Author, Keyword, or ISBN"
							defaultValue={levelABookSearchQuery || ''}
							required
						/>
						<button type="Submit" className="levelHeaderSearch__submit">
							<img src={magnifyingGlass} className="levelHeaderSearch__submit--text" alt="Search Icon"></img>
							<img src={magnifyingGlassRed} className="levelHeaderSearch__submit--text__red" alt="Search Icon"></img>
						</button>
					</div>
				</form>
			</div>
			{props.viewAllBar}
		</ResultsHeader>
		)
}

export default withRouter(LevelHeaderSearch)