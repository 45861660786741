import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './LevelABookCard.scss';
import bookIconMobile from 'images/level-a-book-mobile.svg';
import bookIconDesktop from 'images/level-a-book-desktop.svg';
import magnifyingGlass from 'images/magnifying-glass-icon.svg';
import SolidButton from 'components/content/Button/SolidButton.js';
import * as dumbleData from 'DumbleData';


function LevelABookCard(props) {

	const [searchQuery, setSearchQuery] = useState('');
	/* ref to bind constructor-io autosuggest to search input */
	const inputRef = useRef(null);

	/* SB-19923: TYPEAHEAD DISABLED CONTINGENT ON CONSTRUCTOR-IO DEPENDENCY */
	// let autoComplete = null;
	// useEffect(() => {
	// 	/* bind constructor-io autosuggest methods after script-load */
	// 	if(window.constructorio_default_options){
	// 		// eslint-disable-next-line
	// 		autoComplete = new window.ConstructorioAutocomplete(
	// 			inputRef.current,
	// 			window.constructorio_default_options.landingAutocompleteOptions
	// 			)
	// 		autoComplete.onSelect = onSuggestionSelect;
	// 	}
	// }, [window.constructorio_default_options]);

	function handleChange(event) {
		setSearchQuery(event.target.value);
	}

	function handleSubmit(event) {
		event.preventDefault();
		window.scrollTo({ top: 0, behavior: 'auto'})
		props.history.push({
			pathname: '/results',
			search: '?search&q=' + searchQuery,
			state: {
				flow: 'discovery-flow'
			}
		});
		dumbleData.addSearchAndResults();
		dumbleData.setSearchTypeInputKeyword('user-entered', searchQuery);
		dumbleData.trackHomepageSearch();
	}

	/* handler for when user selects from constructor-io suggestions */
	/* SB-19923: TYPEAHEAD DISABLED CONTINGENT ON CONSTRUCTOR-IO DEPENDENCY */
	// function onSuggestionSelect(event) {
	// 	let selectedSearchQuery = autoComplete.suggestions[event].value;
	// 	if(selectedSearchQuery){
	// 		props.history.push({
	// 			pathname: '/results',
	// 			search: '?search&q=' + selectedSearchQuery
	// 		});
	// 	}
	// 	dumbleData.addSearchAndResults();
	// 	dumbleData.setSearchTypeInputKeyword('type-ahead', selectedSearchQuery, autoComplete.currentValue);
	// 	dumbleData.trackHomepageSearch();
	// }

	/* scrolls search input to top on mobile for better user access to autosuggest dropdown */
	function handleInputFocus(event){
		 const { offsetTop } = inputRef.current
		if(window){
			let isMobile = window.matchMedia('(max-width: 719px)').matches;
			if(isMobile){
				window.scrollTo({ top: offsetTop - 18, behavior: 'smooth'});
			}
		}
	}

	return (
		<div className="levelABookCard">
			<img src={bookIconMobile} srcSet={`${bookIconDesktop} 720w`} alt="Blue Book Icon" className="levelABookCard__book-icon"></img>
			<div className="levelABookCard__title">Level a Book<br/>&nbsp;</div>
			<form className="levelABookCard__form" onSubmit={handleSubmit}>
				<input ref={inputRef} className="levelABookCard__input" type="text" onFocus={handleInputFocus} onChange={handleChange} placeholder="Enter Title, Author, Keyword, or ISBN" required/>
				<SolidButton text="FIND READING LEVELS" icon={magnifyingGlass} alt="Magnifying Glass Icon"/>
			</form>
		</div>
	)
}

export default withRouter(LevelABookCard)