import React from 'react';
import "./Modal.scss";
import PropTypes from 'prop-types';

/** Pass in elements of modal and close modal function with props children so that modal format may be re-used. **/
export default function Modal (props) { 
	return (
		<div>
			<div className= "modal">
				{props.children}
			</div>
			<div className= "overlay"/>
		</div>
	);
}

Modal.propTypes = {
	children: PropTypes.element.isRequired,
	handleModal: PropTypes.func
};
