import React, { Component } from 'react';
import './ViewAllCard.scss';

export class ViewAllCard extends Component {

	render() {
		return (
			<div className="viewAllCard">
				{this.props.children}
			</div>
		)
	}
}

export default ViewAllCard
