import React, { Component } from 'react';
import { ConfigContext } from 'providers/ConfigContext';
import CartItem from '../CartItem/CartItem.js';
import cartIcon from 'images/cart-icon.svg';
import emptyCartImg from 'images/empty-cart.png';
import CloseButtonIcon from 'images/close-button.svg';
import SolidButton from 'components/content/Button/SolidButton';
import OutlineButton from 'components/content/Button/OutlineButton';
import './Cart.scss';
import jsonp from 'jsonp';
import Cookies from 'js-cookie';
import * as dumbleData from 'DumbleData';

export class Cart extends Component {
	static contextType = ConfigContext;
	constructor(props) {
		super(props)
		this.spCatalogName = 'NTS'
		this.state = {
			isActiveMobile: false,
			showAddToCartAlert: false,
			isMultiproduct: false,
			cartInfo: {},
			productsNum: null
		}
	}

	async componentDidMount() {
		this.getCatalogName();
		this.setState({cartInfo: await this.fetchCartInfo()});
	}

	async componentDidUpdate(prevProps) {
		//If user clicked Add to Cart, update cart data
		if (this.props.addToCartItem !== prevProps.addToCartItem && this.props.addToCartItem.length === undefined) {
			this.addCartItem(this.props.addToCartItem.isbn)
		} else if(this.props.addToCartItem !== prevProps.addToCartItem && this.props.addToCartItem.length >= 1) {
			this.addCartItems(this.props.addToCartItem);
		}

		// If profile changes, fetch new cart data
		if (this.props.isSignedIn !== prevProps.isSignedIn) {
			this.setState({cartInfo: await this.fetchCartInfo()})
		}

		if (this.state.cartInfo.cartItems !== undefined) {
			dumbleData.setCartInfo(this.state.cartInfo)
		}
		if ( this.props.handleCartChange ) {
			this.props.handleCartChange(this.state.cartInfo);
		}
		
	}

	// Retrieve cart data from tso endpoint
	fetchCartInfo = async () => {
		try {
			return await new Promise((resolve, reject) => {
				jsonp(this.context.tso.demandware.concat('/Cart-CartSummaryJSONP'), { name: 'jsonCallback' }, async (err, data) => {
					if( !isNaN(data?.totalCartItems) ) {
						resolve(data)
					} else {
						/*
							This endpoint intermittently returns the wrong data if a user tries to
							increment cart item quantity too quickly. In that case, run fetch again.
						*/
						this.setState({cartInfo: await this.fetchCartInfo()})
					}
				});
			});
		}
		catch(error) {
			console.error(error)
			return this.state.cartInfo
		}
	}

	// Deletes an item from a user's cart based on its ID
	deleteCartItem = async (itemId) => {
		try {
			jsonp(this.context.tso.demandware.concat(`/Cart-RemoveFromCartJSONP?itemId=${itemId}&spCatalogName=${this.spCatalogName}`), {name: 'jsonCallback'}, async (err, data) => {
				if (data?.removeItemResponse !== undefined && data.removeItemResponse.status !== 'SUCCESS') {
					console.error(`${data.removeItemResponse.status}: ${data.removeItemResponse.errorMsg}`);
					this.setState({cartInfo: await this.fetchCartInfo()});
				} else {
					this.setState({cartInfo: await this.fetchCartInfo()});
				}
			});
		}
		catch(error) {
			console.error(error)
		}
	}

	// Adds an item to a user's cart based on its ID
	addCartItem = async (itemId) => {
		try {
			let quantity = this.props?.d?.quantity || 1;
			jsonp(this.context.tso.demandware.concat(`/Cart-AddItemToCartJSONP?partNumber=${itemId}-tso-us&quantity=${quantity}&spCatalogName=${this.spCatalogName}`), {name: 'jsonCallback'}, async (err, data) => {
				if (data?.itemAddResponse !== undefined && data.itemAddResponse.status !== 'SUCCESS') {
					console.error(`${data.itemAddResponse.status}: ${data.itemAddResponse.errorMsg}`)
				} else {
					this.toggleAddToCartAlert(false)
					this.setState({cartInfo: await this.fetchCartInfo()});
				}
			});
		}
		catch(error) {
			console.error(error)
		}
	}

	// Adds all items in collection to the user's cart based on their IDs
	addCartItems = async (itemIds) => {
		let loader = document.getElementsByClassName('viewallbar--loader')[0];
		let quantity = this.props.d.quantity || 1;
		let products = itemIds.map(c => ({"pid" : c.data.id + '-tso-us', "qty": quantity, "spCatalogName": this.spCatalogName}) );
		this.setState({'productsNum': products.length * quantity})
		try {
			loader.style.display = 'flex';	
			fetch(`${this.context.tso.demandware}/Cart-AddMultipleItemsToCart`, {
			  method: "POST",
			  mode: "cors",
			  credentials: "include",
			  body: JSON.stringify(products)
			})
			.then(response => response.json())
			.then(async result => {
				if ( result.successItems ) {
					let num = 0;
					result.successItems.forEach((element) => {
					
						if ( element.ats ) {
							num += element.ats;
						} else {
							num += element.qty;
						}
					  });
					loader.style.display = 'none';
					this.setState({productsNum: num});
					this.toggleAddToCartAlert(true);
					this.setState({cartInfo: await this.fetchCartInfo()});
					this.props.resetCart();
				}
			});
		}
		catch (error) {
			console.error(error)
		}
	}

	// Update the quantity of an item
	adjustItemQuantity = async (itemId, quantity) => {
		try {
			jsonp(this.context.tso.demandware.concat(`/Cart-UpdateItemQuantityJSONP?itemId=${itemId}&quantity=${quantity}&spCatalogName=${this.spCatalogName}`), {name: 'jsonCallback'}, async (err, data) => {
				if (data?.updateItemQuantityResponse !== undefined && data.updateItemQuantityResponse.status !== 'SUCCESS') {
					console.error(`${data.updateItemQuantityResponse.status}: ${data.updateItemQuantityResponse.errorMsg}`);
					this.setState({cartInfo: await this.fetchCartInfo()});
				} else {
					this.setState({cartInfo: await this.fetchCartInfo()});
				}
			});
		}
		catch(error) {
			console.error(error)
		}
	}

	// BOOK QIZARD RULES
	toggleCartDropdownMobile = (e) => {
		// Prevent link from opening (rare scenario)
		e.preventDefault()
		// Disables scrolling when cart is opened on mobile
		document.body.classList.toggle('scrollingDisabled')
		this.setState((state) => {
			return {isActiveMobile: !state.isActiveMobile}
		});
	}

	toggleAddToCartAlert = (multiproductFlag) => {
		this.setState({showAddToCartAlert: true, isMultiproduct: multiproductFlag});
		setTimeout(() => {
			this.setState({showAddToCartAlert: false});
		}, 2000);
	}

	handleAddToCartAlertClose = () => {
		this.setState({showAddToCartAlert: false});
	}

	getCatalogName = () => {
		let partnerCookie = Cookies.get('SPS_PTNR');
		let catalog = '';
		switch (partnerCookie) {
			case 'TSO':
				catalog = 'NTS';
				break;
			case 'LP':
				catalog = 'VDK';
				break;
			case 'FACE':
				catalog = 'VDL';
				break;
			case 'NYS':
				catalog = 'NYS';
				break;
			case 'COS':
				catalog = 'COS';
				break;
			case 'RIF':
			case 'NAT':
				catalog = 'NAT';
				break;
			case 'ROR':
				catalog = 'ROR';
				break;
			default:
				catalog = 'NTS';
				break;
		}
		this.spCatalogName = catalog;
	}

	renderAddToCartAlert = () => {
		if(this.props.addToCartItem) {
			return (
				<div className={`cart__add-alert ${this.state.showAddToCartAlert ? "visible" : "hidden"} ${!this.props.hideOnScroll ? "topAligned" : ""} ${this.state.isMultiproduct ? "isMultiproduct" : ""}`}>
					<div className="cart__add-alert--content grid-layout">
						<div className="cart__add-alert--heading">
							{this.state.isMultiproduct ? `${this.state.productsNum} items` : 'Item'} added to Cart
						</div>
						<img
							src={CloseButtonIcon}
							className="cart__add-alert--close"
							onClick={this.handleAddToCartAlertClose}
							alt="Close Button"
							tabIndex="0"></img>
						{this.state.isMultiproduct === false &&
							<div className="cart__add-alert--details">
								<img
									src={this.props.addToCartItem.img}
									className="cart__add-alert--image"
									alt={this.props.addToCartItem.title + ' Cover'}/>
								<div className="cart__add-alert--info">
									<p className="cart__add-alert--format">{this.props.addToCartItem.format}</p>
									<p className="cart__add-alert--title">{this.props.addToCartItem.title}</p>
									<p className="cart__add-alert--price">${this.props.addToCartItem.displayPrice}</p>
								</div>
							</div>
						}
						<div className="cart__add-alert--button-wrapper">
							<SolidButton
								text="CHECKOUT"
								className="cart__add-alert--button"
								handleClick={this.handleCheckoutClick} />
							<OutlineButton
								text="VIEW CART"
								className="cart__add-alert--button"
								handleClick={() => window.location.href=this.context.tso.demandware.concat('/Cart-AjaxCartView')} />
						</div>
					</div>
				</div>
			)
		}
	}

	renderAddAllToCartAlert = () => {
		if(this.props.addToCartItem) {
			return (
				<div className={`cart__add-alert ${this.state.showAddToCartAlert ? "visible" : "hidden"} ${!this.props.hideOnScroll ? "topAligned" : ""}`}>
					<div className="cart__add-alert--content grid-layout">
						<div className="cart__add-alert--heading">
							50 items added to Cart
						</div>
						<img
							src={CloseButtonIcon}
							className="cart__add-alert--close"
							onClick={this.handleAddToCartAlertClose}
							alt="Close Button"
							tabIndex="0"></img>
						<div className="cart__add-alert--button-wrapper">
							<SolidButton
								text="CHECKOUT"
								className="cart__add-alert--button"
								handleClick={this.handleCheckoutClick} />
							<OutlineButton
								text="VIEW CART"
								className="cart__add-alert--button"
								handleClick={() => window.location.href=this.context.tso.demandware.concat('/Cart-AjaxCartView')} />
						</div>
					</div>
				</div>
			)
		}
	}

	renderCart = () => {
		// Cart has items in it
		if (this.state?.cartInfo?.totalCartItems > 0) {
			// Map through the list of cart items and render them as CartItem components
			return (
				<div className="cart__full">
					{this.state.cartInfo.cartItems.map((item) => (
						<CartItem
							key={item.isbn}
							item={item}
							deleteItem={this.deleteCartItem}
							adjustQuantity={this.adjustItemQuantity}/>
					))}
				</div>
			)
		}
		// Cart is empty
		return (
			<div className="cart__empty">
				<img src={emptyCartImg} alt="Empty shopping cart" className="cart__empty-img"/>
				<p className="shoppingCartEmpty">Your Shopping Cart is empty.</p>
			</div>
		)
	}

	// If user clicks checkout and is not signed in, do not link to checkout page
	// Instead, open MyScholastic modal and have user sign in
	handleCheckoutClick = (e) => {
		let sps_ud = Cookies.get('SPS_UD');
		let schl = Cookies.get("SCHL")
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (!sps_ud && !schl) {
			e.preventDefault();
			if (partnerCookie && (partnerCookie === 'FACE' || partnerCookie === 'LP')) {
				this.props.setContractIdModal({ isOpen: true, openedFrom: 'cart'});
			} else {
				this.props.setMyScholasticModal({ isOpen: true, openedFrom: 'cart' });
			}
		} else {
			window.location.href=this.context.tso.demandware.concat('/COCustomer-Start');
		}
	}

	// Render the cart subtotal, item quantity, and view cart/checkout buttons
	renderCheckout = () => {
		const { cartInfo } = this.state;
		if (cartInfo.totalCartItems !== undefined && cartInfo.totalCartItems > 0) {
			return (
				<div className="cart__checkout">
					<p className="fullCartSubtotal">Full Cart Subtotal</p>
					<p className="quantityAndPrice">{cartInfo.totalCartItems} Item{cartInfo.totalCartItems > 1 ? 's' : ''} <span style={{ fontWeight: 'bold'}}>{cartInfo.orderSubTotal}</span></p>
					<a href={this.context.tso.demandware.concat('/COCustomer-Start')} className="checkout__btn" onClick={this.handleCheckoutClick}>Checkout</a>
					<a href={this.context.tso.demandware.concat('/Cart-AjaxCartView')} className="view-cart__btn">View Cart</a>
				</div>
			)
		}
		// If cart is empty, do not render
		return null
	}

	render() {
		const {isActiveMobile, cartInfo} = this.state
		let heading = 'Educator';
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie && partnerCookie !== 'TSO') {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
			heading = `${partnerCookie} Member`;
		}
		return (
			<div className="cart">
				{/* This link/icon is for the desktop version. The reason for the onClick event is in case the cart is
					still open when the user switches to a larger breakpoint (720+), like when changing orientation to landscape */}
				<div className="cart__icon desktop" onClick={isActiveMobile ? this.toggleCartDropdownMobile : null}>
					<a href={this.context.tso.demandware.concat('/Cart-AjaxCartView')}>
						<img src={cartIcon} alt="Cart Icon"/>
						{cartInfo.totalCartItems !== undefined && cartInfo.totalCartItems > 0 ? <p className="totalCartItems">{cartInfo.totalCartItems}</p> : null}
					</a>
				</div>
				{this.renderAddToCartAlert()}
				{/* This icon is for the mobile version */}
				<div className={`cart__icon ${isActiveMobile ? "highlighted" : ""} mobile`} onClick={this.toggleCartDropdownMobile} tabIndex="0">
					<img src={cartIcon} alt="Cart Icon"/>
					{cartInfo.totalCartItems !== undefined && cartInfo.totalCartItems > 0 ? <p className="totalCartItems">{cartInfo.totalCartItems}</p> : null}
				</div>
				<div className={`cart__dropdown ${isActiveMobile ? "visible" : ""}`}>
					<p className="cart__title">{heading} Cart</p>
					<p className="cart__description">Items in this cart only reflect products added from the {partnerCookie ? partnerCookie : 'Teacher'} Store.</p>
					<hr className="cart__hr"></hr>
					{this.renderCart()}
					{this.renderCheckout()}
				</div>
				<div id="cartOverlay"></div>
			</div>

		)
	}
}

export default Cart
