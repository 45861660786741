import React, { useState, useEffect } from 'react';
import BaseSlider from './BaseSlider.js'
import * as dumbleData from 'DumbleData';

export default function SliderDRA (props){
	const cachedMinValue = props.values.readingLevelMin;
	const cachedMaxValue = props.values.readingLevelMax;
	const sliderTickRange = developmentalReadingSliderProps.tickRange;
	const sliderTickMarks = developmentalReadingSliderProps.tickMarks;
	const sliderTickIncrement = 1;
	const sliderDefaultThumbValues = cachedMinValue ? [getSliderTickPositionFromValue(cachedMinValue.toString()), getSliderTickPositionFromValue(cachedMaxValue.toString())]
													: developmentalReadingSliderProps.defaultThumbValues;
	const [selectedMinValue, setSelectedMinValue] = useState(sliderDefaultThumbValues[0]);
	const [selectedMaxValue, setSelectedMaxValue] = useState(sliderDefaultThumbValues[1]);
	/* discoverform change handler to pass into slider updates handler */
	const handleChangeValue = props.handleChangeValue;

	useEffect(() => {
		dumbleData.setTitle('BookWizard:Collection:Reading:SliderDRA');
		dumbleData.trackPageLoad()
	}, [])

	/*
		custom helper methods to translate slider tick values (integers)
		into formatted values by reading level system
	 */
	function formatValueFromSliderTick(value) {
		return developmentalReadingSliderProps.valueTable[value];
	}

	function getSliderTickPositionFromValue(value) {
		return developmentalReadingSliderProps.valueTable.indexOf(value);
	}

	function formatValueForConstructor(value) {
		if(value === 0) {
			return value;
		} else {
			return parseInt(developmentalReadingSliderProps.valueTable[value]);
		}
	}

	return (
			<BaseSlider
				formatValueFromSliderTick={formatValueFromSliderTick}
				sliderTickMarks={sliderTickMarks}
				sliderTickRange={sliderTickRange}
				sliderTickIncrement={sliderTickIncrement}
				sliderDefaultThumbValues={sliderDefaultThumbValues}
				selectedMinValue={selectedMinValue}
				setSelectedMinValue={setSelectedMinValue}
				selectedMaxValue={selectedMaxValue}
				setSelectedMaxValue={setSelectedMaxValue}
				getSliderTickPositionFromValue={getSliderTickPositionFromValue}
				formatValueForConstructor={formatValueForConstructor}
				handleChangeValue={handleChangeValue}
				sliderType="developmental-reading"
			/>
	)
}

const developmentalReadingSliderProps = {
		tickMarks: {
			'0': 'A-1',
			'1': '',
			'2': '',
			'3': '3',
			'4': '',
			'5': '',
			'6': '8',
			'7': '',
			'8': '',
			'9': '14',
			'10': '',
			'11': '',
			'12': '20',
			'13': '',
			'14': '',
			'15': '30',
			'16': '',
			'17': '',
			'18': '40',
			'19': '',
			'20': '',
			'21': '70'
		},
		tickRange: [0,21],
		defaultThumbValues: [0,21],
		valueTable: [
		"A-1",
		"1",
		"2",
		"3",
		"4",
		"6",
		"8",
		"10",
		"12",
		"14",
		"16",
		"18",
		"20",
		"24",
		"28",
		"30",
		"34",
		"38",
		"40",
		"50",
		"60",
		"70"
		]
}