import React, { Component } from 'react'
import './Footer.scss'
import scholasticLogo from 'images/scholastic-logo-white-outline.svg'

export class Footer extends Component {
    render() {
        return (
            <footer id="globalfooter">
                <a href="http://www.scholastic.com/" className="globalfooter__scholastic-logo">
                    <img src={scholasticLogo} alt="Scholastic Logo"/>
                </a>
                <div className="globalfooter__links">
                    <a href="https://www.scholastic.com/privacy.htm" className="globalfooter__link--privacy" target="_blank" rel="noopener noreferrer">
                        <span className="globalfooter__link--privacy-underline">PRIVACY POLICY</span>
                    </a>
                    <a href="https://www.scholastic.com/terms.htm" className="globalfooter__link" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    <a href="https://www.scholastic.com/content/corp-home/donotsell.html" className="globalfooter__link" target="_blank" rel="noopener noreferrer">Do Not Sell My Info</a>
                    <a href="https://www.scholastic.com/aboutscholastic/" className="globalfooter__link" target="_blank" rel="noopener noreferrer">About Scholastic</a>
                </div>
                <p className="globalfooter__description">TM ® & © {(new Date().getFullYear())} Scholastic Inc. All Rights Reserved.</p>
            </footer>
        )
    }
}

export default Footer

