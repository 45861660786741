import React from 'react'
import PropTypes from 'prop-types'
import './BookALikeButton.scss'
import bookalikeIcon from 'images/bookalike.svg'

/** Displays a button on BookCards that allows users to 
 *  see similar books to the one they are currently viewing
 */
export default function BookALikeButton(props) {
    return (
        <button className={`book-a-like-button ${props.type}`} onClick={props.handleBookALikeClick}>
            <img className="book-a-like-icon" src={bookalikeIcon} alt=""></img>Book-a-Like
        </button>
    )
}

/** Required props:
 *  handleBookALikeClick: event handler for clicking the button
 *  type: either 'desktop' or 'mobile', used for rendering purposes
 */
BookALikeButton.propTypes = {
    handleBookALikeClick: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
}