import React, { Component } from 'react';
import * as dumbleData from 'DumbleData';
import './LandingPage.scss';
import Nav from 'components/structure/Nav/Nav.js';
import LevelingChartButton from 'components/content/LandingPage/LevelingChartButton/LevelingChartButton.js';
import LevelABookCard from 'components/content/LandingPage/LevelABookCard/LevelABookCard.js';
import DiscoverCard from 'components/content/LandingPage/DiscoverNewBooksCard/DiscoverCard.js';
import CollectionCard from 'components/content/LandingPage/CollectionCard/CollectionCard.js';

export class LandingPage extends Component {
	componentDidMount() {
		dumbleData.setPageType('Landing');
		dumbleData.setTitle('BookWizard:Landing');
		dumbleData.trackPageLoad()
	}

	render() {
		return (
			<React.Fragment>
				<Nav
					isSignedIn={this.props.isSignedIn}
					setIsSignedIn={this.props.setIsSignedIn}
					myScholasticModal={this.props.myScholasticModal}
					setMyScholasticModal={this.props.setMyScholasticModal}
					contractIdModal={this.props.contractIdModal}
					setContractIdModal={this.props.setContractIdModal}
				/>
				<div className="landingPage animatedGradient">
					<div className="grid-layout">
						<LevelABookCard />
						<DiscoverCard />
						<CollectionCard />
					</div>
					<LevelingChartButton />
				</div>
			</React.Fragment>
		)
	}
}

export default LandingPage
