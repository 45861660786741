import React, { Component} from 'react'
import Cookies from 'js-cookie';
import './Profile.scss'

import profileIcon from 'images/profile-icon.svg'
import { ConfigContext } from 'providers/ConfigContext';
import * as dumbleData from 'DumbleData';
import { isWishlistEnabled } from '../../../utils/Wishlist';


export class Profile extends Component {


	static contextType = ConfigContext;
	constructor(props){
		super(props);
		this.state = {
			userFirstName: '',
			userInitials: '',
			user: {},
			dropDownExpandedOnMobile: false,
			myScholasticOrigin: '',
			myScholasticSignInOrigin: '',
			profileDropdownOrderedKeys: [
				"#my-profile",
				"#my-wishlist",
				"#order-history",
				"#subscriptions",
				"#downloads",
				"#manage-campaigns",
				"#gift-card",
				"#sign-out"
			],
			profileDropdownTitles: {
				"#my-profile": "My Profile",
				"#my-wishlist": "My Wish List",
				"#order-history": "Order History",
				"#subscriptions": "Subscriptions",
				"#downloads": "Downloads",
				"#manage-campaigns": "Manage Campaigns",
				"#gift-card": "Gift Card",
				"#sign-out": "Sign Out"
			},
			/* to be merged with response to profile-links endpoint on login */
			profileDropdownLinks: {
				"#sign-out": "/api/logout"
			}
		}

		this.generateDropdownLinks = this.generateDropdownLinks.bind(this);
		this.toggleDropdownOnMobile = this.toggleDropdownOnMobile.bind(this);
		this.generateUserIcon = this.generateUserIcon.bind(this);
		this.renderDropdown = this.renderDropdown.bind(this);
		this.checkSignedInStatus = this.checkSignedInStatus.bind(this);
		this.fetchMyProfileLinks = this.fetchMyProfileLinks.bind(this);
		this.fetchUserData = this.fetchUserData.bind(this);
		this.signoutMyScholastic = this.signoutMyScholastic.bind(this);
		this.setDomainToScholastic = this.setDomainToScholastic.bind(this);
		this.setStrictDomain = this.setStrictDomain.bind(this);
		this.renderMyScholasticModal = this.renderMyScholasticModal.bind(this);
		this.renderContractIdModal = this.renderContractIdModal.bind(this);
		this.parseUserNameData = this.parseUserNameData.bind(this);
		this.signUserOn = this.signUserOn.bind(this);
		this.myScholasticOnLoginSuccess = this.myScholasticOnLoginSuccess.bind(this);
		this.myScholasticOnRegistrationSuccess = this.myScholasticOnRegistrationSuccess.bind(this);
		this.myScholasticOnLoginClose = this.myScholasticOnLoginClose.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.callbackPartnerLogin = this.callbackPartnerLogin.bind(this);
		this.handleMyAccountMessage = this.handleMyAccountMessage.bind(this);
		this.contractCheck = this.contractCheck.bind(this);

		/* assign myScholastic callbacks to top level */
		window.myScholasticOnLoginSuccess = this.myScholasticOnLoginSuccess;
		window.myScholasticOnRegistrationSuccess = this.myScholasticOnRegistrationSuccess;
		window.myScholasticOnLoginClose = this.myScholasticOnLoginClose;
		window.handleMyAccountMessage = this.handleMyAccountMessage;

	}

	componentDidMount(){
		/* inject myScholastic env origin into component state */
		this.setState({ myScholasticOrigin: this.context.myschl.origin, myScholasticSignInOrigin: this.context.myschl.signInOrigin }, () => {
			this.checkSignedInStatus();
		});
		window.addEventListener("message", (event) => {this.callbackPartnerLogin(event)}, true);
		window.addEventListener('message', (event) => {this.handleMyAccountMessage(event)}, true);
	}

	handleLogin() {
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie && (partnerCookie === 'FACE' || partnerCookie === 'LP')) {
			this.props.setContractIdModal({isOpen: true, openedFrom: "profile"});
		} else {
			this.props.setMyScholasticModal({ isOpen: true, openedFrom: "profile"});
		}
	}

	callbackPartnerLogin(event) {
		if (event && event.origin === this.context.tso.origin) {
			try {
        		let responseMessage  = (
					typeof event.data === 'object' &&
					!Array.isArray(event.data) &&
					event.data !== null
				) ? event.data : JSON.parse(event.data);
				if ( responseMessage.source !== 'PARTNER_LOGIN' ) return;
				switch(responseMessage.status) {
					case 'CLOSE':
					case 'ERROR':
						this.props.setContractIdModal({isOpen: false});
							break;
					case 'SUCCESS_LOGIN':
						this.props.setContractIdModal({isOpen: false});
							sessionStorage.setItem('contractNumber', responseMessage.data.contractNumber);
							if (sessionStorage.getItem('partnerCheckout') ) window.loginPageType = "checkout";
							this.props.setMyScholasticModal({ isOpen: true, openedFrom: "profile"});
							break;
					case 'SUCCESS_CLOSE':
						this.props.setContractIdModal({isOpen: false});
							sessionStorage.setItem('contractNumber', responseMessage.data.contractNumber);
							break;
					case 'SUCCESS_REGISTER':
						this.props.setContractIdModal({isOpen: false});
							sessionStorage.setItem('contractNumber', responseMessage.data.contractNumber);
							//asoProfile.registration();
							break;
					case 'REDIRECT':
						this.props.setContractIdModal({isOpen: false});
							window.location.href = responseMessage.data.url;
							break;
					case 'LINK_NEW_TAB':
							window.open(responseMessage.data.url).focus();
							break;
					default:
						this.props.setContractIdModal({isOpen: false});
						break;
				}
			} catch (e) {
				console.log(e);
			}
		} 
	}

  componentWillUnmount() {
    window.removeEventListener('message', (event) => {this.handleMyAccountMessage(event)}, true);
  }

	handleMyAccountMessage(event) {
		if (event && event.orgin === this.myScholasticSignInOrigin) {
			try {
        		var responseMessage  = (
					typeof event.data === 'object' &&
					!Array.isArray(event.data) &&
					event.data !== null
				) ? event.data : JSON.parse(event.data);


				let {status, data} = responseMessage;

				//user clicked close icon
				if (status === 'CLOSE') {
					this.props.setMyScholasticModal({ isOpen: false, openedFrom: ""})
				// user clicked sign in button for api call to /api/login
				} else if (status === 'SUCCESS' && data !== null) {
					// login is successful, we will be accepting the user object coming from IAM
					// store or use user data as needed

					var validContract = (async () => {
						return await this.contractCheck();
					 })();
					if ( !validContract ) return;
					this.setState({user: data.user});
					this.checkSignedInStatus();
					this.props.setMyScholasticModal({ isOpen: false, openedFrom: ""})
				} else if (status === 'REDIRECT') {
					this.props.setMyScholasticModal({ isOpen: false, openedFrom: ""})
					window.location.href = data.data.url;
				} else if (status === 'RESET') {
					// reset password email will be sent to user and flow will continue from there
					this.props.setMyScholasticModal({ isOpen: false, openedFrom: ""})
				}
			} catch(error) {
				console.log(error);
			}
		}
    }
	

	checkSignedInStatus(){
		let sps_ud = Cookies.get('SPS_UD');
		if(sps_ud){
			let userNameData = this.parseUserNameData(sps_ud);
			this.setState({...userNameData });
			this.fetchMyProfileLinks();
			this.fetchUserData();
		} else {
			this.props.onSignInChange(false);
		}
		
	}

	fetchMyProfileLinks() {
		return fetch(this.state.myScholasticOrigin.concat("/api/profile-links?storefront=tso"), {
			method: 'GET',
			cache: 'no-store',
			credentials: 'include'
		})
		.then((response) => {
			if (response.status === 200) {
				return response.json();
			}
		})
		.then((data) => {
			/*
				merge returned profile links data with signon link data
			*/
			this.setState({
				profileDropdownLinks: {...this.state.profileDropdownLinks, ...data}
			});
			this.props.onSignInChange(true);
		})
		.catch((error) => {
			console.log(error);
			return null;
		})
	}

	fetchUserData() {
		let data = this.state.user;
		if ( !Object.keys(data).length === 0 ) {
			if(data.personas.hasOwnProperty('educator')) {
				let gradeBand = data.personas.educator.school.gradeRoles;
				dumbleData.setGrade(gradeBand);
				dumbleData.setUserType('educator');
			} else if(data.personas.hasOwnProperty('parent')) {
				dumbleData.setUserType('parent');
			}
		}
		
	}

	/*
		clears SPS_SESSION, SPS_SESSION_SECURE, SPS_TSP,
		SPS_TSP_SECURE and SCHL cookies
	*/
	signoutMyScholastic() {
		let demandware = this.context.tso.demandware;
		let partnerCookie = Cookies.get('SPS_PTNR');
		
		return fetch(`${demandware}/Login-IDAMSignOut`, {
			method: "GET",
			mode: "cors",
			credentials: "include"
		  })
		.then(response =>  response.json())
		.then(result => {
			this.setState({
				profileDropdownLinks: {
					"#sign-out": "/api/logout"
				}
			});
			this.props.onSignInChange(false);
			console.log('signoutMyScholastic Success:', result);
			let default_urls = {
                tso: "/teachers-ecommerce/teacher/tsohomepage.html",
                sso: "/parent-ecommerce/parent-store.html",
                cos: "/teachers-ecommerce/cos/sample-order-homepage.html",
                lp: "/teachers-ecommerce/lp/lppartner.html",
                nat: "/teachers-ecommerce/rif/rifpartner.html",
                nys: "/teachers-ecommerce/nys/nyspartner.html",
                face: "/teachers-ecommerce/face/facepartner.html",
                rif: "/teachers-ecommerce/rif/rifpartner.html",
                ror: "/teachers-ecommerce/ror/rorpartner.html"
            };
			
			if (partnerCookie && partnerCookie !== 'TSO') {
				window.location.href = this.context.tso.origin + default_urls[partnerCookie.toLocaleLowerCase()];
			}
			
		})
		.catch(error => {
			console.error('signoutMyScholastic Error:', error);
		});
	}

	/*
		toggle page domain to address CORs issues
		btw myscholastic iframe domain and page
	 */
	setDomainToScholastic() {
		if (document.domain.indexOf("scholastic") !== -1) {
			document.domain = "scholastic." + document.domain.split(".").pop();
		}
	}

	setStrictDomain() {
		// Set up a try-catch to handle issues with this call on Firefox :'(
		try {
			document.domain = document.location.hostname;
		}
		catch(err) {
			console.error(err);
		}
	}

	renderMyScholasticModal(){
		if(this.props.myScholasticModal.isOpen){
			this.setDomainToScholastic();
			let referer = 'bw';
			return (
				<div className="profile__myscholastic-modal">
					<div className="profile__myscholastic-modal--overlay"></div>
					<div className="profile__myscholastic-modal--container">
						<iframe
							className="profile__myscholastic-modal--iframe"
							src={this.state.myScholasticSignInOrigin.concat("/sign-in.html?ref="+referer)}
							title="myscholastic-modal"
							frameBorder="0"
							allowFullScreen>
						</iframe>
					</div>
				</div>
			)
		}
		this.setStrictDomain();
		return null;
	}

	renderContractIdModal(page){
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie) {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
		} else {
			partnerCookie = 'tso';
		}
		if(this.props.contractIdModal.isOpen){
			if ( page === 'checkout' ) sessionStorage.setItem('partnerCheckout', true);
			return (
				<div className="new-partner-modal">
					<div className="modal new-partner-login">
						<div className="modal-dialog">
							<iframe className={`iframe ${partnerCookie.toLowerCase()}`} src={`${this.context.tso.origin}/on/demandware.store/Sites-tso-us-Site/default/Cart-LoginPartner?partnerType=${partnerCookie.toLowerCase()}&action=login`} title="contract id" frameBorder="0" allowtransparency="true" scrolling="no" style={{'height': 0, 'width': '100%', 'border': 'none', 'overflow': 'hidden'}}></iframe>
						</div>
					</div>
				</div>
			)
		}
		return null;
	}

	/* parse initials and username from sps_ud cookie and set to UI state */
	parseUserNameData(sps_ud){
		let userNameArray = sps_ud.split('|').filter(function(el,idx){ return idx === 2 || idx === 3});
		let userName = userNameArray[0];
		let userInitials = userNameArray.map(function(el){ return el.slice(0,1).toUpperCase()}).join('');
		return { userFirstName: userName, userInitials: userInitials };
	}

	signUserOn(){
		let sps_ud = Cookies.get('SPS_UD');
		let signOnState = { sps_ud: sps_ud };
		if(sps_ud){
			// If the modal was opened from the checkout button, send user to checkout screen
			if (this.props.myScholasticModal.openedFrom === 'cart') {
				window.location = this.context.tso.demandware.concat('/COCustomer-Start')
			} else if (this.props.myScholasticModal.openedFrom === 'wishlist') {
			}
			let userNameData = this.parseUserNameData(sps_ud);
			this.setState({ ...signOnState, ...userNameData });
			this.fetchMyProfileLinks();
			this.fetchUserData();
		}
	}

	/* MyScholastic top-level hooks (called from within child iframe) */
	myScholasticOnLoginSuccess() {
		this.signUserOn();
	}

	myScholasticOnRegistrationSuccess () {
		this.signUserOn();
	}

	myScholasticOnLoginClose () {
		this.props.setMyScholasticModal({ isOpen: false, openedFrom: ""});

	}

	async contractCheck () {
		let flag = false;
		let demandware = this.context.tso.demandware;
		let contractNumber = sessionStorage.getItem('contractNumber');
		if ( contractNumber ) {
			var contractType = Cookies.get('SPS_PTNR');
		} else if ( contractNumber === null ) {
			return true;
		}
       
		async function partnerId() {
			var data = new FormData();
			data.append('contractType', contractType);
			data.append('contractId', contractNumber);
			data.append('mode', 'validateContract');
			
			let res =  await fetch(`${demandware}/Login-IDAMPartnerSignIn`, {
			  method: "POST",
			  mode: "cors",
			  async: false,
			  credentials: "include",
			  body: data
			}).then(async d => {
				if (d.ok) {
					const d_1 = await d.json();
					return ({ d_1 });
				}
	
				const error = await d.json();
				return ({ error });
			});
			return res;
			
		};

		let newData = await partnerId();
		if ( newData.d_1.success === true ) {
			console.log('Partner Login Success: '+contractType+' Contract Number Validation Success.');
			flag =  true;
		} else {
			console.log('Partner Login Failed: '+contractType+' Contract Number Validation Failed.');
			alert('Partner Login Failed: '+contractType+' Contract Number Validation Failed.');
			window.href = `${this.context.tso.origin}/teachers-ecommerce/teacher/tsohomepage.html`;
		}
	
		
		sessionStorage.removeItem('contractNumber');
		sessionStorage.removeItem('partnerCheckout');
		return flag;
	}

	toggleDropdownOnMobile(){
		this.setState({ dropDownExpandedOnMobile: !this.state.dropDownExpandedOnMobile })
	}

	generateUserIcon(){
		if(this.props.isSignedIn){
			return (
				<div className="profile__icon" onClick={this.toggleDropdownOnMobile} tabIndex="0">
					<div className="profile__icon--signedin">
						<div className="profile__user-initials">{this.state.userInitials}</div>
					</div>
				</div>
			)
		}
		
		return (
			<div className="profile__icon" onClick={this.handleLogin} tabIndex="0">
				<img className="profile__icon--anonymous" src={profileIcon} alt="Profile Icon"/>
			</div>
		)
	}

	generateDropdownLinks() {
		let myScholasticOrigin = this.state.myScholasticOrigin;
		let titles = this.state.profileDropdownTitles;
		let demandware = this.context.tso.demandware;
		let links = this.state.profileDropdownLinks;
		let signoutMyScholastic = this.signoutMyScholastic;
		let store = 'tso';
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie) {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
			store = partnerCookie.toLowerCase();
		}
		let linkList = this.state.profileDropdownOrderedKeys.filter(function(key){
			if(links[key]){
				return true;
			}
			return false;
		}).map(function(key,idx){
			if(key === "#sign-out"){
				// eslint-disable-next-line
				return (<div key={idx} id="signout" onClick={signoutMyScholastic} tabIndex='0'>{titles[key]}</div>);
			}
			if (key === "#my-wishlist" && isWishlistEnabled(store)) {
				return (<a key={idx} target="_blank" rel="noopener noreferrer" href={`${demandware}/Wishlist-Show`}>{titles[key]}</a>);
			} else if (key === "#my-wishlist" && !isWishlistEnabled(store)) {
				return '';
			}
			return (<a key={idx} target="_blank" rel="noopener noreferrer" href={links[key]}>{titles[key]}</a>);
		})
		return linkList;
	}

	renderDropdown() {
		if(this.props.isSignedIn) {
			return (
				<div className={this.state.dropDownExpandedOnMobile ? 'profile__dropdown expanded': 'profile__dropdown'}>
					<p className="profile__greeting">Hello, {this.state.userFirstName}</p>
					{this.generateDropdownLinks()}
				</div>
			);
		}
		return null;
	}


	render() {
		return (
			<div className="profile">
				{this.generateUserIcon()}
				{this.renderDropdown()}
				{this.renderMyScholasticModal()}
				{this.renderContractIdModal()}
			</div>
		)
	}
}

export default Profile
