import React, { useState, useEffect } from 'react';
import BaseSlider from './BaseSlider.js'
import * as dumbleData from 'DumbleData';

export default function SliderGR (props){
	const cachedMinValue = props.values.readingLevelMin;
	const cachedMaxValue = props.values.readingLevelMax;
	const sliderTickRange = guidedReadingSliderProps.tickRange;
	const sliderTickMarks = guidedReadingSliderProps.tickMarks;
	const sliderTickIncrement = 1;
	const sliderDefaultThumbValues = cachedMinValue ? [cachedMinValue,cachedMaxValue] : guidedReadingSliderProps.defaultThumbValues;
	const [selectedMinValue, setSelectedMinValue] = useState(sliderDefaultThumbValues[0]);
	const [selectedMaxValue, setSelectedMaxValue] = useState(sliderDefaultThumbValues[1]);
	/* discoverform change handler to pass into slider updates handler */
	const handleChangeValue = props.handleChangeValue;

	useEffect(() => {
		dumbleData.setTitle('BookWizard:Collection:Reading:SliderGR');
		dumbleData.trackPageLoad()
	}, [])

	/*
		custom helper methods to translate slider tick values (integers)
		into formatted values by reading level system
	 */
	function formatValueFromSliderTick(value) {
		return value === 27 ? "Z+" : String.fromCharCode(value + 64);
	}

	function getSliderTickPositionFromValue(value) {
		return value.charCodeAt(0) - 64;
	}

	function formatValueForConstructor(value) {
		return value;
	}

	return (
			<BaseSlider
				formatValueFromSliderTick={formatValueFromSliderTick}
				sliderTickMarks={sliderTickMarks}
				sliderTickRange={sliderTickRange}
				sliderTickIncrement={sliderTickIncrement}
				sliderDefaultThumbValues={sliderDefaultThumbValues}
				selectedMinValue={selectedMinValue}
				setSelectedMinValue={setSelectedMinValue}
				selectedMaxValue={selectedMaxValue}
				setSelectedMaxValue={setSelectedMaxValue}
				getSliderTickPositionFromValue={getSliderTickPositionFromValue}
				formatValueForConstructor={formatValueForConstructor}
				handleChangeValue={handleChangeValue}
				sliderType="guided-reading"
			/>
	)
}

const guidedReadingSliderProps = {
		tickMarks: {
			'1': 'A',
			'2': '',
			'3': '',
			'4': 'D',
			'5': '',
			'6': '',
			'7': '',
			'8': '',
			'9': 'I',
			'10': '',
			'11': '',
			'12': '',
			'13': '',
			'14': 'N',
			'15': '',
			'16': '',
			'17': 'Q',
			'18': '',
			'19': '',
			'20': 'T',
			'21': '',
			'22': '',
			'23': 'W',
			'24': '',
			'25': '',
			'26': '',
			'27': 'Z+'
		},
		tickRange: [1,27],
		defaultThumbValues: [1,27]
}