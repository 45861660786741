import React, { Component } from 'react'
import constructorio from 'utils/Constructorio.js';
import './SubjectPage.scss'
import TextCTA from 'components/content/CollectionForm/TextCTA'
import * as dumbleData from 'DumbleData';

export class SubjectPage extends Component {
	componentDidMount() {
		let cachedFacets = this.props.cachedFacets;
		let currentFacets = this.getUserFacetSelections();
		if(Object.keys(this.props.values.subjects).length === 0 || cachedFacets !== currentFacets) {
			this.fetchSubjectFacets();
		}
		dumbleData.setTitle('BookWizard:Collection:Subject');
		dumbleData.trackPageLoad()
	}

	fetchSubjectFacets = () => {
		let selectedGrades = this.props.getSelectedFacets('gradeLevels');
		let selectedCategories = this.props.getSelectedFacets('categories');
		constructorio.browse.getBrowseResults(
			'product-type',
			'books/individual-titles',
			{
				filters: {
					...(this.props.values.readingLevelSystem !== '') && {[this.props.values.readingLevelSystem]: this.props.values.readingLevelMin + "-" + this.props.values.readingLevelMax},
					'grade': selectedGrades,
					'super-genre': selectedCategories
				}
			}
		).then((data) => {
			let subjects = data.response.facets.filter(facet => facet.name === 'subject');
			//Get 12 most popular subjects by count
			let sortedSubjects = subjects[0].options.sort((a, b) => b.count - a.count).slice(0, 12);

			//Create state object from sortedSubjects array
			let subjectObj = {};
			sortedSubjects.forEach(subject => subjectObj[subject.value] = {'display_name': subject.display_name, 'selected': false});

			this.props.setDynamicFacets('subjects', subjectObj);
			this.props.handleChangeValue('cachedSubjectFacets', this.getUserFacetSelections());

		}).catch((error) => {
			console.log(error);
		});
	}

	getUserFacetSelections = () => {
		const { values } = this.props;
		const excludedFacets = ['subjects', 'genres'];

		const filtered = Object.keys(values)
			.filter(key => !excludedFacets.includes(key))
			.reduce((obj, key) => {
				obj[key] = values[key];
				return obj;
			}, {});
		return JSON.stringify(filtered);
	}

	render() {
		const { values, handleChange } = this.props;
		let subjectOptions = Object.entries(values.subjects).map(([key, value]) =>
			<TextCTA
				text={value.display_name}
				key={key}
				className="subjectPage__cta"
				selected={value.selected}
				handleClick={() => handleChange('subjects', key)}
			/>
		)

		return (
			<div className="subjectPage">
				{subjectOptions}
			</div>
		)
	}
}

export default SubjectPage