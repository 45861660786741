import React from 'react';
import PropTypes from 'prop-types'
import './ViewAllBar.scss';

/** Renders a sticky bar on the results page, applies to 
 *  search, discover, and book-a-like flows, contains links/info
 *  about the results
 */
export default function ViewAllBar(props) {
	const { isBuildACollection, isBookALikeBar, hideOnScroll, children } = props

	return (
		<div id="viewAllBar" className={`viewAllBar${isBuildACollection ? " build-a-collection" : ""}${isBookALikeBar ? " book-a-like-bar" : ""}${hideOnScroll === false ? " sticky" : ""}`}>
			{children}
		</div>
	)
}

/** Component props:
 *  isBookALikeBar: a boolean telling whether this bar is part of the BookALikeHeader, and styling accordingly
 *  hideOnScroll: also for BookALike, changes styling based on scroll position
 *  children: content for the bar
 */
ViewAllBar.propTypes = {
	isBuildACollection: PropTypes.bool,
	isBookALikeBar: PropTypes.bool,
	hideOnScroll: PropTypes.bool,
	children: PropTypes.array.isRequired
}