import React, { Component } from 'react'
import './ReadingLevelPage.scss'
import TextCTA from 'components/content/DiscoverForm/TextCTA'
import SliderACR from 'components/content/DiscoverForm/ReadingLevelPage/Slider/SliderACR.js'
import SliderDRA from 'components/content/DiscoverForm/ReadingLevelPage/Slider/SliderDRA.js'
import SliderGR from 'components/content/DiscoverForm/ReadingLevelPage/Slider/SliderGR.js'
import SliderLM from 'components/content/DiscoverForm/ReadingLevelPage/Slider/SliderLM.js'
import * as dumbleData from 'DumbleData';

export class ReadingLevelPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			readingCTAs: [
				{
					text: "Guided Reading",
					value: "guidedReadingLevel",
					defaultRange: [1,27],
					defaultRangeDisplay: ['A','Z+']
				},
				{
					text: "Lexile Measure",
					value: "lexileLevel",
					defaultRange: [-870,1570],
					defaultRangeDisplay: ['BR870L','1570L']
				},
				{
					text: "Developmental Reading Assessment (DRA)",
					value: "draLevel",
					defaultRange: [0,21],
					defaultRangeDisplay: ['A-1','70']
				},
				{
					text: "Accelerated Reader (ACR)",
					value: "acceleratedReaderLevel",
					defaultRange: ['0.2','9999'],
					defaultRangeDisplay: ['0.2','18.0+']
				}
			]
		}

		this.renderCTAs = this.renderCTAs.bind(this);
		this.renderSlider = this.renderSlider.bind(this);
	}

	componentDidMount() {
		/** Runs the page tracking only if the slider is not being shown
		 *  (not when the previous button is clicked on the categories page)
		 */
		if (!this.props.values.slider.showSlider.selected) {
			dumbleData.setTitle('BookWizard:Discover:Reading');
			dumbleData.trackPageLoad()
		}
	}

	componentDidUpdate(prevProps) {
		// Runs the page tracking when the user clicks the previous button from the slider screen
		if (!this.props.values.slider.showSlider.selected && prevProps.values.slider.showSlider.selected) {
			dumbleData.setTitle('BookWizard:Discover:Reading');
			dumbleData.trackPageLoad()
		}
	}

	renderCTAs() {
		const { values, handleChange, handleChangeValue } = this.props;
		let CTAs = this.state.readingCTAs.map((cta,idx) => {
			return (
					<TextCTA 
						key={idx} 
						text={cta.text} 
						handleClick={() => {
							handleChange('readingLevelSystem', cta.value);
							handleChangeValue('readingLevelMin', cta.defaultRange[0]);
							handleChangeValue('readingLevelMax', cta.defaultRange[1]);
							handleChangeValue('readingLevelMinDisplay', cta.defaultRangeDisplay[0]);
							handleChangeValue('readingLevelMaxDisplay', cta.defaultRangeDisplay[1]);
							handleChangeValue('lexileCode', '');
						}}
						className="readingLevelPage__cta"
						selected={values.readingLevelSystem === cta.value}
					/>
				);
		})
		if(values.slider.showSlider.selected){
			return null;
		}
		return CTAs;
	}

	renderSlider(){
		const { values, handleChangeValue } = this.props;
		let rendered;
		switch(values.readingLevelSystem) {
			case "acceleratedReaderLevel":
			rendered = <SliderACR values={values} handleChangeValue={handleChangeValue}/>;
			break;
			case "draLevel":
			rendered = <SliderDRA values={values} handleChangeValue={handleChangeValue}/>;
			break;
			case "guidedReadingLevel":
			rendered = <SliderGR values={values} handleChangeValue={handleChangeValue}/>;
			break;
			case "lexileLevel":
			rendered = <SliderLM values={values} handleChangeValue={handleChangeValue}/>;
			break;
			default:
			rendered = null;
		}
		return values.slider.showSlider.selected ? rendered : null;
	}

	render() {
		const { values } = this.props;
		return (
			<div className={`readingLevelPage ${values.slider.showSlider.selected ? "slider" : ""}`}>
				{this.renderCTAs()}
				{this.renderSlider()}
			</div>
		)
	}
}

export default ReadingLevelPage