import React from 'react'
import './ZeroResultsPage.scss'
import SolidButton from 'components/content/Button/SolidButton.js';

export default function ZeroResultsPage(props) {
    return (props.flow === 'discovery-flow' ?
        <div className="zero-results-page">
            <p className="title">We couldn’t find any matches for that. Please try again.</p>
            <p className="question">Not finding what you’re looking for?</p>
            <p className="description">Try our discovery tool to browse by Guided Reading, Lexile<sup>®</sup> Measure, DRA, or Grade Level and find the best books to meet your needs.</p>
            <SolidButton
                color="blue"
                text="DISCOVER NEW BOOKS"
                className="discover-new-items"
                handleClick={() => props.history.push({pathname: '/discover'})}/>
        </div>
        :
        <div className="zero-results-page">
            <p className="title">We couldn’t find any matches for that. Please try again.</p>
            <p className="question">Not finding what you’re looking for?</p>
            <p className="description">Try our discovery tool to browse by Guided Reading, Lexile<sup>®</sup> Measure, DRA, or Grade Level and find the best books to meet your needs.</p>
            <SolidButton
                color="blue"
                text="DISCOVER NEW BOOKS"
                className="discover-new-items"
                handleClick={() => props.history.push({pathname: '/collection'})}/>
        </div>
    )
}
