import React from 'react'
import PropTypes from 'prop-types'
import './TrashButton.scss'
import trashIcon from 'images/trash-icon.svg'

/** Displays a button on BookCards that allows users to 
 *  see similar books to the one they are currently viewing
 */
export default function TrashButton(props) {
    return (
        <button className={'delete-button'} onClick={props.handleDeleteBookCard}>
            <img className="trash-icon" src={trashIcon} alt=""></img>
        </button>
    )
}

/** Required props:
 *  handleBookALikeClick: event handler for clicking the button
 *  type: either 'desktop' or 'mobile', used for rendering purposes
 */
TrashButton.propTypes = {
    handleDeleteBookCard: PropTypes.func.isRequired
}