import React from 'react'
import styled from 'styled-components'
import variables from 'styles/_variables.scss';

export default function TextCTA(props) {
	return (
		<StyledButton 
			className={`${props.className} ${props.selected ? "selected" : ""}`}
			onClick={props.handleClick}
			tabIndex="0" >
			<ContentWrapper tabIndex="-1">
				<Text>{props.text}</Text>
			</ContentWrapper>
		</StyledButton>
	)
}

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: normal;
	min-height: 54px;
	margin: 6px;
	border-radius: 5px;
	transition: box-shadow 0.2s ease-in-out;
	box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px ${variables.grey3};
	background-color: ${variables.white};
	box-sizing: border-box;
	padding: 12px 20px;
	@media (min-width: 720px) {
		min-height: 81px;
		margin: 10px;
	}

	&:hover {
		cursor: pointer;
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
	}
	&:focus {
		border: solid 2px ${variables.captainBlue};
	}
	&.selected {
		border: solid 2px ${variables.paleBlue};
		background-color: ${variables.aliceBlue};
		p {
			font-weight: 500;
			letter-spacing: -0.1px;
			color: ${variables.darkBlue};
		}
		&:focus {
			box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
			border: solid 2px ${variables.captainBlue};
		}
	}
`;

const ContentWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Text = styled.p`
	font-family: museo-sans;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.19;
	text-align: center;
	color: ${variables.grey1};
	margin: 0px;
	hyphens: auto;
`;