export function isWishlistEnabled(store) {
    let wlEnabled = {
        "tso": true,
        "sso": true,
        "rif": false,
        "lp": true,
        "face": true,
        "cos": false,
        "nys": true,
        "ror": true
    };

    return wlEnabled[store];
}