import React from 'react'
import styled from 'styled-components';
import variables from 'styles/_variables.scss';

export default function Button(props) {
	let imageHtml;

	if(props.icon) {
		imageHtml = <img src={props.icon} className="icon" alt={props.alt}></img>
	}

	if(!props.color || props.color === 'red') {
		return (
			<SolidButton onClick={props.handleClick} disabled={props.disabled} className={props.className} iconLeft={props.iconLeft}>
				{/* eslint-disable-next-line */}
				<a className="text">{props.text}</a>
				{imageHtml}
			</SolidButton>
		)
	} else if (props.color === 'blue') {
		return (
			<SolidButtonBlue onClick={props.handleClick} disabled={props.disabled} className={props.className} iconLeft={props.iconLeft}>
				{/* eslint-disable-next-line */}
				<a className="text">{props.text}</a>
				{imageHtml}
			</SolidButtonBlue>
		)
	} else if (props.color === 'shady-blue') {
		return (
			<SolidButtonShadyBlue onClick={props.handleClick} disabled={props.disabled} className={props.className} iconLeft={props.iconLeft}>
				{/* eslint-disable-next-line */}
				<a className="text">{props.text}</a>
				{imageHtml}
			</SolidButtonShadyBlue>
		)
	}
}

const SolidButton = styled.button`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 40px;
	min-width: 100px;
	padding: 0px 20px;
	border-radius: 100px;
	background-color: ${variables.cliffordRed};
	border: none;
	text-decoration: none;
	.text {
		font-family: museo-sans;
		font-size: 11px;
		font-weight: bold;
		line-height: 1.09;
		letter-spacing: 1.5px;
		color: ${variables.white};
	}
	.icon {
		margin: ${props => props.iconLeft ? "0 8px 0 0" : "0 0 0 8px"};
		order: ${props => props.iconLeft ? -1 : 1};
	}
	&:hover:enabled, &:active:enabled {
		cursor: pointer;
		background-color: ${variables.milanoRed};
	}
	&:focus {
		background-color: ${variables.milanoRed};
		&:before {
			display: block;
		}
	}
	&:before {
		display: none;
		content: " ";
		position: absolute;
		top: -4px;
		left: -4px;
		right: -4px;
		bottom: -4px;
		border: 2px solid ${variables.milanoRed};
		border-radius: 100px;
	}
	&:disabled {
		opacity: 0.5;
		background-color: ${variables.grey1};
	}
`;

const SolidButtonBlue = styled(SolidButton)`
	background-color: ${variables.captainBlue};
	&:hover:enabled, &:active:enabled, &:focus{
		background-color: ${variables.darkBlue};
	}
	&:before {
		border: 2px solid ${variables.darkBlue};
	}
`;

const SolidButtonShadyBlue = styled(SolidButton)`
	background-color: ${variables.shadyBlueOpaque0_5};
	&:hover:enabled, &:focus{
		background-color: ${variables.prussianBlue};
	}
	&:active:enabled{
		background-color: ${variables.deepBlue};
	}
	&:before {
		border: 2px solid ${variables.prussianBlue};
	}
`;

