import React, { useState, useEffect } from 'react';
import BaseSlider from './BaseSlider.js'
import * as dumbleData from 'DumbleData';

export default function SliderDRA (props){
	const cachedMinValue = props.values.readingLevelMin;
	const cachedMaxValue = props.values.readingLevelMax;
	const sliderTickRange = acceleratedReaderSliderProps.tickRange;
	const sliderTickMarks = acceleratedReaderSliderProps.tickMarks;
	const sliderTickIncrement = 1;	
	const sliderDefaultThumbValues = cachedMinValue ? [getSliderTickPositionFromValue(cachedMinValue),getSliderTickPositionFromValue(cachedMaxValue)] 
													: acceleratedReaderSliderProps.defaultThumbValues;
	const [selectedMinValue, setSelectedMinValue] = useState(sliderDefaultThumbValues[0]);
	const [selectedMaxValue, setSelectedMaxValue] = useState(sliderDefaultThumbValues[1]);
	/* discoverform change handler to pass into slider updates handler */
	const handleChangeValue = props.handleChangeValue;

	/** The second empty array parameter ensures that this is only run once,
	 *  imitating componentDidMount
	 */
	useEffect(() => {
		dumbleData.setTitle('BookWizard:Discover:Reading:SliderACR');
		dumbleData.trackPageLoad()
	}, [])

	/* 
		custom helper methods to translate slider tick values (integers)
		into formatted values by reading level system 
	 */
	function formatValueFromSliderTick(value) {
		if(value === 179) {
			return '18.0+';
		} else {
			return ((value + 1)/10).toFixed(1);
		}
	}

	function getSliderTickPositionFromValue(value) {
		if(value === '9999') {
			return 179;
		} else {
			return (parseFloat(value) * 10) - 1;
		}
	}

	function formatValueForConstructor(value) {
		// If user selects the slider's upper bound (tick mark 179) then use 9999 as the max value
		// for the Constructor call in order to account for any ACR value above 18
		if(value === 179) {
			return '9999';
		} else {
			return formatValueFromSliderTick(value);
		}
	}

	return (
			<BaseSlider 
				formatValueFromSliderTick={formatValueFromSliderTick}
				sliderTickMarks={sliderTickMarks}
				sliderTickRange={sliderTickRange}
				sliderTickIncrement={sliderTickIncrement}
				sliderDefaultThumbValues={sliderDefaultThumbValues}
				selectedMinValue={selectedMinValue}
				setSelectedMinValue={setSelectedMinValue}
				selectedMaxValue={selectedMaxValue}
				setSelectedMaxValue={setSelectedMaxValue}
				getSliderTickPositionFromValue={getSliderTickPositionFromValue}
				formatValueForConstructor={formatValueForConstructor}
				handleChangeValue={handleChangeValue}
				sliderType="accelerated-reader"
			/>
	)
}

const acceleratedReaderSliderProps = {
		tickMarks: {
			'1': '0.2',
			'14': '',
			'29': '3.0',
			'44': '',
			'59': '6.0',
			'74': '',
			'89': '9.0',
			'104': '',
			'119': '12.0',
			'134': '',
			'149': '15.0',
			'164': '',
			'179': '18.0+'
		},
		tickRange: [1,179],
		defaultThumbValues: [1,179]
}