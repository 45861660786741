import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import './DiscoverCard.scss';
import byocIconMobile from 'images/build-your-own-collection-mobile.svg';
import byocIconDesktop from 'images/build-your-own-collection-desktop.svg';
import SolidButton from 'components/content/Button/SolidButton.js';

export class DiscoverCard extends Component {
	constructor() {
		super();
		this.launchDiscoverModal = this.launchDiscoverModal.bind(this);
	}

	launchDiscoverModal() {
		window._satellite.track('build-your-own-collection-begin');
		this.props.history.push({pathname: '/discover'});
	}

	render() {
		return (
			<div className="discoverCard">
				<img
					src={byocIconMobile}
					srcSet={`${byocIconDesktop} 720w`}
					alt="Glasses Icon"
					className="discoverCard__glasses-icon">
				</img>
				<div className="discoverCard__title"><span>Build Your Own</span> Book Collection</div>
				<div className="discoverCard__text">
					<span>Get personalized book</span> <span>recommendations and build</span> your own collection.
				</div>
				<SolidButton text="GET RECOMMENDATIONS" handleClick={this.launchDiscoverModal}/>
			</div>
		)
	}
}

export default withRouter(DiscoverCard)