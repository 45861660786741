import React, { Component } from 'react'
import './BudgetPage.scss'
import TotalCTA from 'components/content/CollectionForm/TotalCTA';
import * as dumbleData from 'DumbleData';

export class BudgetPage extends Component {
	componentDidMount() {
		dumbleData.setTitle('BookWizard:Collection:Budget');
		dumbleData.trackPageLoad()
	}

	render() {
		const { values, handleChange } = this.props;
		return (
			<div className="budgetPage">
				<TotalCTA
					text="Total Budget"
					msg="Maximum"
					value={values.budget}
					placeholder="$10,000"
					className="budgetPage__cta"
					selected={values.totalType === 'totalBudget'}
					handleClick={(event) => {
						handleChange('totalType', 'totalBudget')
					}}
					handleChange={(event) => {
						handleChange('budget', event.target.value)
						handleChange('books', '')
					}}
					/>
				<div className="options-spanner">or</div>
				<TotalCTA
					text="Total Books"
					msg="Number of books"
					value={values.books}
					placeholder="1"
					className="budgetPage__cta"
					selected={values.totalType === 'totalBooks'}
					handleClick={() => {
						handleChange('totalType', 'totalBooks')
					}}
					handleChange={(event) => {
						handleChange('books', event.target.value)
						handleChange('budget', '')
					}}
					/>
			</div>
		)
	}
}

export default BudgetPage