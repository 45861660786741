import React, { Component } from 'react'
import './LevelingChartButton.scss'
import ExternalLinkIcon from 'images/external-link-icon.svg'
import { ConfigContext } from 'providers/ConfigContext';

export class LevelingChartButton extends Component {
    static contextType = ConfigContext;
    render() {
        let tsoOrigin = this.context.tso.origin;
        return (
            <a href={tsoOrigin + "/teachers-ecommerce/teacher/guided-reading-leveling-chart.html"} className="levelingChartButton" target="_blank" rel="noopener noreferrer" tabIndex="0">
                <div className="levelingChart__text">LEVELING CHART</div>
                <img src={ExternalLinkIcon} alt="External Link Icon"></img>
            </a>
        )
    }
}

export default LevelingChartButton
