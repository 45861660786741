import React, { Component } from 'react'
import './GradePage.scss'
import * as dumbleData from 'DumbleData';
import TextCTA from 'components/content/CollectionForm/TextCTA'

export class GradePage extends Component {
	componentDidMount() {
		dumbleData.setTitle('BookWizard:Collection:Grade');
		dumbleData.trackPageLoad()
	}

	render() {
		const { values, handleChange } = this.props;
		let gradeLevelOptions = Object.entries(values.gradeLevels).map(([key, value]) =>
			<TextCTA
				text={value.display_name}
				key={key}
				className="gradePage__cta"
				selected={value.selected}
				handleClick={() => handleChange('gradeLevels', key)}
			/>
		)

		return (
			<div className="gradePage">
				{gradeLevelOptions}
			</div>
		)
	}
}

export default GradePage