/* 
    Conditionally add a 'noindex, nofollow' meta tag for a specified route.
    Prevents Google from indexing or crawling links on a page.
*/
export function setNoIndex() {
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'noindex, nofollow';
    document.head.appendChild(metaRobots);
}

/* 
    Remove the 'noindex, nofollow' meta tag for pages we want Google to index.
*/
export function removeNoIndex() {
    const metaRobots = document.querySelector("[name='robots']");
    if(metaRobots) {
        metaRobots.parentNode.removeChild(metaRobots);
    }
}