import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ResultsHeader from '../ResultsHeader/ResultsHeader'
import BookCard from 'components/content/BookCard/BookCard'
import { ConfigContext } from 'providers/ConfigContext';
import bookalikeIcon from 'images/bookalike.svg'
import './BookALikeHeader.scss'
import ViewAllBar from '../ViewAllBar/ViewAllBar';

/** Renders the header that appears on the top of the Results Page
 *  after the user clicks on a Book-a-Like button from one of the
 *  Book Cards. Contains the Book Card of the book that was clicked,
 *  and a Book-a-Like bar that sticks to the top when scrolling
 */
export default function BookALikeHeader(props) {
	const configContext = useContext(ConfigContext);
	const tsoOrigin = configContext.tso.origin;
	const book = props.bookALikeItem

	return (
		<ResultsHeader hideOnScroll={props.hideOnScroll} handleHeaderUpdate={props.handleHeaderUpdate} headerOffset={props.headerOffset}>
			<BookCard
				isbn={book.data.id}
				img={book.data.image_url}
				title={book.value}
				format={book.data.displayFormat}
				grade={book.data.displayGrade}
				listPrice={book.data.listPrice}
				onSale={book.data.on_sale}
				displayPrice={book.data.displayPrice}
				author={book.data.author ? props.decodeText(book.data.author) : ''}
				illustrator={book.data.illustrator ? props.decodeText(book.data.illustrator) : ''}
				url={tsoOrigin + book.data.url}
				facets={book.data.facets ? book.data.facets : ''}
				handleAddToCart={props.handleAddToCart}
				addToCartButtonLabel={JSON.parse(book.data.button).label.trim().toUpperCase()}
				inWishlist={props.inWishlist}
				productId={book.data.mdmProductId}
				handleWishlistClick={props.handleWishlistClick}
				handleBookALikeClick={props.handleBookALikeClick}
			/>
			<ViewAllBar isBookALikeBar={true} hideOnScroll={props.hideOnScroll}>
				<img className="book-a-like-bar__icon" src={bookalikeIcon} alt=""></img>
				<p className="book-a-like-bar__text">Book-a-Like to <cite>{book.value}</cite></p>
			</ViewAllBar>
		</ResultsHeader>
	)
}

/** Required props:
 *  bookALikeItem: book information that goes in the Book Card
 *  hideOnScroll + handleHeaderUpdate: part of the sticky nav logic
 *  handleAddToCart, inWishlist, handleWishlistClick: necessary for CTAs to function
 *  decodeText: function for formatting authors/illustrator names
 */
BookALikeHeader.propTypes = {
	bookALikeItem: PropTypes.object.isRequired,
	hideOnScroll: PropTypes.bool.isRequired,
	handleHeaderUpdate: PropTypes.func.isRequired,
	handleAddToCart: PropTypes.func.isRequired,
	inWishlist: PropTypes.bool.isRequired,
	handleWishlistClick: PropTypes.func.isRequired,
	decodeText: PropTypes.func.isRequired,
}