import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import Cookies from 'js-cookie';

let constructorKeyEnv = process.env.REACT_APP_TSO_CONSTRUCTOR_ENV_KEY.trimEnd();
let constructorKeyProd = process.env.REACT_APP_TSO_CONSTRUCTOR_PROD_KEY.trimEnd();
let partnerCookie = Cookies.get('SPS_PTNR');

if (partnerCookie) {
	if (partnerCookie === 'NAT') partnerCookie = 'RIF';
	constructorKeyEnv = process.env[`REACT_APP_${partnerCookie}_CONSTRUCTOR_ENV_KEY`].trimEnd();
	constructorKeyProd = process.env[`REACT_APP_${partnerCookie}_CONSTRUCTOR_PROD_KEY`].trimEnd();
}

let constructorio = new ConstructorIOClient({
    apiKey: constructorKeyEnv
});

/** Need a separate Constructor object for Book-a-like
 *  since the stage key does not return any recommendations
 */
export let recommendationsConstructor = new ConstructorIOClient({
    apiKey: constructorKeyProd
})

// Pod name for Constructor recommendations module
export const constructorPod = "tso_product_page_2"

export default constructorio