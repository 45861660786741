import React, { Component } from 'react'
import { ConfigContext } from 'providers/ConfigContext'
import './CartItem.scss'
import minusSign from 'images/minus.svg'
import plusSign from 'images/plus.svg'
import trashIcon from 'images/trash-icon.svg'
import Cookies from 'js-cookie'

export class CartItem extends Component {
	static contextType = ConfigContext;

	constructor(props) {
		super(props)
		this.quantityInput = React.createRef()
	}

	// appends image url to pais endpoint
	generatePaisLink = () => {
		const {isbn} = this.props.item
		const size = 200
		const paisLink = this.context.paisURL.concat(`/v1/products/identifiers/isbn/${isbn}/primary/renditions/${size}?useMissingImage=true`)
		return paisLink
	}

	// Adds some alt text to product images
	generateAltText = () => 'Primary image for ' + this.props.item.title

	// Replaces any html codes in the title with their character equivalent, such as &#39; which is the apostrophe
	decodeTitle = () => {
		let txt = document.createElement("textarea");
		txt.innerHTML = this.props.item.title;
		return txt.value;
	}

	// Takes a product title like Charlotte's Web and converts it to:
	// charlottes-web-9780590302715.html
	transformTitleToPDPURL = () => {
		let productPage = this.decodeTitle()
		// Remove special characters from title, such as apostrophes, semicolons, etc
		productPage = productPage.replace(/[.,/#!'$%^&*;:{}=\-_`~()]/g,"")
		// Replace spaces with dashes
		productPage = productPage.replace(/\s/g , "-")
		productPage = productPage.toLowerCase()
		// add a dash, isbn number, and .html to the end
		return productPage + '-' + this.props.item.isbn + '.html'
	}

	// Generates a link to a product's store page by formatting its title and isbn
	getPDP = () => {
		let store = 'teacher';
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie && partnerCookie !== 'TSO') {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
			store = partnerCookie.toLowerCase();
		}
		let productPage = this.transformTitleToPDPURL();
		return `${this.context.tso.origin}/teachers-ecommerce/${store}/books/${productPage}`
	}

	// Check if input is a valid number before updating cart
	onQuantityChange = (e) => {
		let newValue = parseInt(e.target.value)
		if (e.target.validity.valid && newValue !== this.props.item.qty) {
			this.props.adjustQuantity(this.props.item.orderItemId, newValue)
		}
	}

	onQuantityChange2 = (e) => {
		let newValue = parseInt(e.target.value)
		 if (newValue > 999) {
			e.target.value = this.props.item.qty;
		}
	}

	// Decrement quantity by 1
	decreaseQuantity = () => {
		this.quantityInput.current.stepDown()
		this.props.adjustQuantity(this.props.item.orderItemId, this.quantityInput.current.value)
	}

	// Increment quantity by 1
	increaseQuantity = () => {
		this.quantityInput.current.stepUp()
		this.props.adjustQuantity(this.props.item.orderItemId, this.quantityInput.current.value)
	}

	render() {
		const {orderItemId} = this.props.item;
		return (
			<div className="cart__item">
				<div className="image__container">
					<a href={this.getPDP()}>
						<img src={this.generatePaisLink()} alt={this.generateAltText()} className="product__image"></img>
					</a>
				</div>
				<div className="info__container">
					<div className="type-and-title">
						<p className="product__type">{this.props.item.productType}</p>
						<p className="product__title"><a href={this.getPDP()}>{this.decodeTitle(this.props.item.title)}</a></p>
					</div>
					<div className="price-and-change-quantity__buttons">
						<p className="product__price">{this.props.item.totalPrice}</p>
						<button className="btn__minus" onClick={this.decreaseQuantity} disabled={this.props.item.qty === 1}>
							<img src={minusSign} alt="Decrease quantity by 1"></img>
						</button>
						<input
							aria-label="Adjust quantity of item"
							type="number"
							ref={this.quantityInput}
							key={this.props.item.qty}
							defaultValue={this.props.item.qty}
							min="1"
							max="999"
							onBlur={this.onQuantityChange}
							onKeyUp={this.onQuantityChange2}
							required>
						</input>
						<button className="btn__plus" onClick={this.increaseQuantity}>
							<img src={plusSign} alt="Increase quantity by 1"></img>
						</button>
						<button className="delete__button" onClick={() => this.props.deleteItem(orderItemId)}>
							<img src={trashIcon} alt="Delete item from cart"></img>
						</button>
					</div>
				</div>
				<hr className="item__hr"></hr>
			</div>
		)
	}
}

export default CartItem
