import React, { useEffect, useRef } from 'react';
import './AboutBooksPage.scss'
import styled from 'styled-components';
import variables from 'styles/_variables.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as dumbleData from 'DumbleData';

const min = 0;
const max = 999;

export default function AboutBooksPage (props) {
	const { values, handleChange } = props;
	const quantityInputRef = useRef();
	const maxInputRef = useRef();
	const maxPerBookDropdownOptions = [
		"None",
		"3",
		"5",
		"10",
		"15",
		"20"
	];

	useEffect(() => {
		dumbleData.setTitle('BookWizard:Collection:About');
		dumbleData.trackPageLoad();
	}, []);

	return (
		<div className="aboutBooksPage">
			<div className="quantityOptionsWrapper">
				<Msg>Quantity per book</Msg>
				<TextField
					type="number"
					variant="outlined"
					inputRef={quantityInputRef}
					margin="normal"
					placeholder="1"
					style={{ width: 159 }}
					value={props.values.quantity}
					inputProps={{ min, max }}
					onChange={(e) => {
						var value = parseInt(e.target.value, 10);

						if (value < min) value = min;
						if (value > max) value = max;
						e.target.value = value;

						handleChange('quantity', value)
					}}
				/>
			</div>
			<div className="options-spanner"></div>
			<div className="maxOptionsWrapper">
				<Msg>Max $ per book</Msg>
				<Autocomplete
					id="maxPerBook"
					disableClearable={true}
					disableListWrap={true}
					popupIcon={<KeyboardArrowDownIcon/>}
					onChange={(event, newValue) => {
						if (newValue !== null) {
							handleChange('maxPrice', newValue);
						}
					}}
					defaultValue={values.maxPrice ? values.maxPrice : 'None'}
					style={{ width: 159 }}
					options={maxPerBookDropdownOptions}
					getOptionLabel={(option) => option}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							inputRef={maxInputRef}
							margin="normal"/>
					)}
				/>
			</div>
			<div className="oosOptionsWrapper">
				<FormControlLabel
		          value="end"
		          control={
		          	values.oosToggle !== 'false' ?
		          		<Checkbox
		          			color="primary"
		          			defaultChecked
		          			onChange={(event) => {
		          				handleChange('oosToggle', event.target.checked);
		          			}}
		          		/>
		          		:
		          		<Checkbox
		          			color="primary"
		          			onChange={(event) => {
		          				handleChange('oosToggle', event.target.checked);
		          			}}
		          		/>
		          	}
		          label="Show out of stock books"
		          labelPlacement="end"
		        />
			</div>
		</div>
	)

}

const Msg = styled.p`
	font-family: museo-sans;
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: 8px;
	color: ${variables.grey1};
}`;