import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import BaseSlider from './BaseSlider.js'
import * as dumbleData from 'DumbleData';

export default function SliderLM (props){
	const cachedMinValue = props.values.readingLevelMin;
	const cachedMaxValue = props.values.readingLevelMax;
	const cachedLexileCode = props.values.lexileCode;
	const cachedLexileCodeSelector = props.values.lexileCode ? props.values.lexileCode.split(":")[0].toLowerCase() : "";

	const [sliderTickRange, setSliderTickRange] = useState(lexileCodeSliderProps[cachedLexileCodeSelector || 'all'].tickRange);
	const [sliderTickMarks, setSliderTickMarks] = useState(lexileCodeSliderProps[cachedLexileCodeSelector || 'all'].tickMarks);
	const sliderTickIncrement = 10;	
	const [sliderDefaultThumbValues, setSliderDefaultThumbValues] = useState(cachedMinValue 
																	? [cachedMinValue,cachedMaxValue] 
																	: lexileCodeSliderProps[cachedLexileCodeSelector || 'all'].defaultThumbValues);
	const [selectedMinValue, setSelectedMinValue] = useState(sliderDefaultThumbValues[0]);
	const [selectedMaxValue, setSelectedMaxValue] = useState(sliderDefaultThumbValues[1]);
	/* discoverform change handler to pass into slider updates handler */
	const handleChangeValue = props.handleChangeValue;

	const lexileCodeInputRef = useRef();

	useEffect(() => {
		/* disable text input on lexile code dropdown */
		lexileCodeInputRef.current.setAttribute('readonly','')
		dumbleData.setTitle('BookWizard:Discover:Reading:SliderLM');
		dumbleData.trackPageLoad()
	}, []);

	/* render new slider on selection of new lexile code */
	function updateSliderProps(lexileCodeKey){
		setSliderTickRange(lexileCodeSliderProps[lexileCodeKey].tickRange)
		setSliderDefaultThumbValues(lexileCodeSliderProps[lexileCodeKey].defaultThumbValues)
		setSliderTickMarks(lexileCodeSliderProps[lexileCodeKey].tickMarks)
		setSelectedMinValue(lexileCodeSliderProps[lexileCodeKey].defaultThumbValues[0])
		setSelectedMaxValue(lexileCodeSliderProps[lexileCodeKey].defaultThumbValues[1])
	}

	/* 
		custom helper methods to translate slider tick values (integers)
		into formatted values by reading level system 
	 */
	function formatValueFromSliderTick(value) {
		if(value < 0){
			return `BR${Math.abs(value)}L`;
		}
		return `${value}L`;
	}

	function getSliderTickPositionFromValue(value) {
		if(value.indexOf('BR') === 0){
			return parseInt(value.slice(2,value.indexOf('L'))) * -1;
		}
		return parseInt(value.slice(0,value.indexOf('L')));
	}

	function formatValueForConstructor(value) {
		return value;
	}

	function renderLexileCodeDropdown() {
		return (
			<Autocomplete
				id="lexileCodeDropdown"
				disableClearable={true}
				disableListWrap={true}
				popupIcon={<KeyboardArrowDownIcon/>}
				onChange={(event, newValue) => {
					if(newValue !== null){
						let newLexileCodeKey = newValue.split(':')[0].toLowerCase();
						updateSliderProps(newLexileCodeKey);
						handleChangeValue('lexileCode', newValue);
					}
				}}			
				defaultValue={cachedLexileCode || "All"}
				style={{ width: 230 }}
				options={lexileCodeDropdownOptions}
				getOptionLabel={(option) => option}
				renderInput={(params) => (
					<TextField 
						{...params}
						label={"Lexile Code"}
						variant="outlined"
						inputRef={lexileCodeInputRef}
						margin="normal"/>
				)}
			/>
		);
	}

	return (
			<BaseSlider 
				formatValueFromSliderTick={formatValueFromSliderTick}
				sliderTickMarks={sliderTickMarks}
				sliderTickRange={sliderTickRange}
				sliderTickIncrement={sliderTickIncrement}
				sliderDefaultThumbValues={sliderDefaultThumbValues}
				selectedMinValue={selectedMinValue}
				setSelectedMinValue={setSelectedMinValue}
				selectedMaxValue={selectedMaxValue}
				setSelectedMaxValue={setSelectedMaxValue}
				getSliderTickPositionFromValue={getSliderTickPositionFromValue}
				formatValueForConstructor={formatValueForConstructor}
				/* do not include renderLexileCodeDropdown prop for non-lexile sliders */
				renderLexileCodeDropdown={renderLexileCodeDropdown}
				handleChangeValue={handleChangeValue}
				sliderType="lexile"
			/>
	)
}

const lexileCodeDropdownOptions = [
	"All",
	"AD: Adult Directed",
	"BR: Beginning Reader",
	"GN: Graphic Novel",
	"HL: High-Low",
	"IG: Illustrated Guide",
	"NC: Non-Conforming",
	"NP: Non-Prose"
]

const lexileCodeSliderProps = {
	all: {
		tickMarks: {
			'-870': 'BR870L',
			'-750': '',
			'-500': 'BR500L',
			'-250': '',
			'0': '0L',
			'250': '',
			'500': '500L',
			'750': '',
			'1000': '1000L',
			'1250': '',
			'1570': '1570L'
		},
		tickRange: [-870,1570],
		defaultThumbValues: [-870,1570]
	},
	ad: {
		tickMarks: {
			'10': '10L',
			'100': '100L',
			'200': '',
			'300': '300L',
			'400': '',
			'500': '500L',
			'600': '',
			'700': '700L',
			'800': '',
			'900': '900L',
			'1000': '',
			'1100': '1100L',
			'1200': '',
			'1300': '1300L',
			'1410': '1410L',
		},
		tickRange: [10,1410],
		defaultThumbValues: [10,1410]
	},
	br: {
		tickMarks: {
			'-870': 'BR870L',
			'-800': '',
			'-700': 'BR700L',
			'-600': '',
			'-500': 'BR500L',
			'-400': '',
			'-300': 'BR300L',
			'-200': '',
			'-100': 'BR100L',
			'-10': 'BR10L',
		},
		tickRange: [-870,-10],
		defaultThumbValues: [-870,-10]
	},
	gn: {
		tickMarks: {
			'130': '130L',
			'200': '200L',
			'300': '',
			'400': '400L',
			'500': '',
			'600': '600L',
			'700': '',
			'800': '800L',
			'900': '',
			'1000': '1000L',
			'1100': '',
			'1120': '1120L'
		},
		tickRange: [130,1120],
		defaultThumbValues: [130,1120]
	},
	hl: {
		tickMarks: {
			'60': '60L',
			'100': '',
			'200': '200L',
			'300': '',
			'400': '400L',
			'500': '',
			'600': '600L',
			'700': '',
			'800': '800L',
			'900': '',
			'1000': '',
			'1070': '1070L'
		},
		tickRange: [60,1070],
		defaultThumbValues: [60,1070]
	},
	ig: {
		tickMarks: {
			'250': '250L',
			'300': '',
			'400': '400L',
			'500': '',
			'600': '600L',
			'700': '',
			'800': '800L',
			'900': '',
			'1000': '1000L',
			'1100': '',
			'1200': '1200L',
			'1290': '1290L'
		},
		tickRange: [250,1290],
		defaultThumbValues: [250,1290]
	},
	nc: {
		tickMarks: {
			'100': '100L',
			'200': '',
			'300': '300L',
			'400': '',
			'500': '500L',
			'600': '',
			'700': '700L',
			'800': '',
			'900': '900L',
			'1000': '',
			'1100': '1100L',
			'1200': '',
			'1300': '1300L',
			'1400': '',
			'1500': '',
			'1570': '1290L'
		},
		tickRange: [100,1570],
		defaultThumbValues: [100,1570]
	},
	np: {
		tickMarks: {
			'130': '130L',
			'200': '200L',
			'300': '',
			'400': '400L',
			'500': '',
			'600': '600L',
			'700': '',
			'800': '800L',
			'900': '',
			'960': '960L'
		},
		tickRange: [130,960],
		defaultThumbValues: [130,960]
	},
}