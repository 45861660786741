import React, { useState, useEffect } from 'react';
import './DiscoveryHeaderFilters.scss';
import ResultsHeader from 'components/content/ResultsPage/ResultsHeader/ResultsHeader.js';
import FilterCTA from 'components/content/ResultsPage/FilterCTA';
import SolidButton from 'components/content/Button/SolidButton.js';
import LightningIcon from 'images/lightning-icon.svg';
import PlusIcon_white from 'images/plus-white.svg';
import MinusIcon_white from 'images/minus-white.svg';

function DiscoveryHeaderFilters (props) {

	var mql = window.matchMedia('(max-width: 719px)');
	let isMobile = mql.matches;
	const [selectionsAreDisplayed, setSelectionsAreDisplayed] = useState(!isMobile);

	/** ensure that default on mobile is no filter display
	 *  and always display filters on re-size to desktop
	 */
	useEffect(() => {
		const handleWindowResize = () => { 
			if(window.innerWidth < 720) { 
				if(selectionsAreDisplayed){
					setSelectionsAreDisplayed(false);
				}
			} else if(!selectionsAreDisplayed){
				setSelectionsAreDisplayed(true);
			}
		}
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, [selectionsAreDisplayed]);

	function toggleShowSelections() {
		setSelectionsAreDisplayed(!selectionsAreDisplayed);
		/** dispatch resize event to trigger header-size re-render
		 *  in all related components
		 */
		window.dispatchEvent(new Event('resize'));
	}

	function handleFilterClick(step) {
		props.state.step = step;
		props.state.isFilterUpdate = true;
		props.history.push({
			pathname: '/discover',
			state: props.state
		});
	}

	function handleStartOver() {
		props.history.push({
			pathname: '/discover'
		});
	}

	function generateFilterData(state) {
		let readingLevelSystemAcronym = {
			"acceleratedReaderLevel": "ACR",
			"draLevel": "DRA",
			"guidedReadingLevel": "GR",
			"lexileLevel": "Lexile"
		}

		/** grab filter display names by page and flatten array
		 *  one level (flatMap) for rendering
		 */
		let filtersArray = ['gradeLevels','categories','subjects','genres'].map((el,idx)=>{
			return Object.entries(state[el]).filter(([key, value]) => value.selected).map(filter => [idx+2,filter[1].display_name]);	
		}).flatMap(x => x);

		/* parse readnglevel display range */
		if(state.readingLevelSystem){
			let acronym = readingLevelSystemAcronym[state.readingLevelSystem];
			let range = state.readingLevelMinDisplay + '-' + state.readingLevelMaxDisplay;
			filtersArray.push([2,acronym + ": " + range]);
		}
		return filtersArray;
	}


	function renderFilterCTAs() {
		let filterDataArray = generateFilterData(props.state);
		return filterDataArray.map((el,idx) => 
			<FilterCTA 
				text={el[1]}
				key={idx}
				className="resultsPageFilter__cta"
				handleClick={() => handleFilterClick(el[0])}
			/>
		)
	}

	return (
		<ResultsHeader hideOnScroll={props.hideOnScroll} handleHeaderUpdate={props.handleHeaderUpdate} headerOffset={props.headerOffset}>
			<div className="discoveryHeaderFilters grid-layout">
				<div className="discoveryHeaderFilters__message">
					<p>Great! These books match your selections.</p>
				</div>
				{selectionsAreDisplayed &&
					<div className="discoveryHeaderFilters__wrapper">
						{renderFilterCTAs()}
					</div>
				}
				<div className="discoveryHeaderFilters__buttons">
				{isMobile &&
					<SolidButton 
						color="shady-blue" 
						text={`${selectionsAreDisplayed ? 'HIDE' : 'SHOW'} SELECTIONS`}
						icon={selectionsAreDisplayed ? MinusIcon_white : PlusIcon_white}
						iconLeft
						alt="Toggle Show Selections Button" 
						handleClick={toggleShowSelections}/>
				}
					<SolidButton 
						color="shady-blue" 
						text={"START OVER"}
						icon={LightningIcon}
						iconLeft
						alt="Start Over Button" 
						handleClick={handleStartOver}/>
				</div>
			</div>
			{props.viewAllBar}
		</ResultsHeader>
		)
}

export default DiscoveryHeaderFilters