import React , { useEffect, useState, useRef } from 'react';
import './ResultsHeader.scss';

function ResultsHeader (props) {
	/** this library is a custom react hook to grab rendered
	 *  element dimensions before screen paint
	 */
	const [viewAllBarHeight, setViewAllBarHeight] = useState(0)
	const resizeObserver = useRef(null)

	const { handleHeaderUpdate, headerOffset, hideOnScroll, children } = props;

	/** Uses a ResizeObserver object to detect size changes in the view all bar
	 *  and results header components (from page resize or content changes), and
	 *  updates the state accordingly
	 */
	useEffect(() => {
		resizeObserver.current = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (entry.target.id === "viewAllBar") {
					setViewAllBarHeight(entry.target.clientHeight)
				} else {
					handleHeaderUpdate(entry.target.clientHeight)
				}
			}
		})
		resizeObserver.current.observe(document.getElementById("viewAllBar"))
		resizeObserver.current.observe(document.getElementById("resultsHeader"))
		// When the component unmounts, unobserve all components
		return function cleanup() {
			resizeObserver.current.disconnect()
		}
	}, [handleHeaderUpdate])

	/** set scroll amounts dynamically and responsively
	 *  based on rendered header height
	 */
	let isMobile = window.matchMedia('(max-width: 719px)').matches;
	let noScrollTop = isMobile ? '90px' : '70px'
	let scrollTopSearch = isMobile ? noScrollTop : '-94px';
	let scrollTopDiscoveryFilters = isMobile ? noScrollTop : '-' + (headerOffset - (viewAllBarHeight + 4)) + 'px';
	let scrollStyles = props.hideOnScroll !== undefined 
					? {'top': `${hideOnScroll ? noScrollTop : scrollTopDiscoveryFilters}`}
					: {'top': `${hideOnScroll ? noScrollTop : scrollTopSearch}`}

	return (
		<div id="resultsHeader" className="resultsHeader animatedGradient" style={scrollStyles}>
			{children}
		</div>
	)
}

export default ResultsHeader
