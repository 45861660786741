import React, { Component } from 'react'
import './LevelPage.scss'
import ReadingLevelImage from 'images/reading-level.png'
import GradeLevelImage from 'images/grade-level.png'
import ImageCTA from 'components/content/CollectionForm/ImageCTA'
import * as dumbleData from 'DumbleData';

export class LevelPage extends Component {
	componentDidMount() {
		dumbleData.setTitle('BookWizard:Collection:Level');
		dumbleData.trackPageLoad()
	}

	render() {
		const { values, handleChange } = this.props;
		return (
			<div className="levelPage">
				<ImageCTA
					image={ReadingLevelImage}
					text="Reading Level"
					className="levelPage__cta"
					selected={values.level === 'reading'}
					handleClick={() => handleChange('level', 'reading')}/>
				<ImageCTA
					image={GradeLevelImage}
					text="Grade Level"
					className="levelPage__cta"
					selected={values.level === 'grade'}
					handleClick={() => handleChange('level', 'grade')}/>
			</div>
		)
	}
}

export default LevelPage