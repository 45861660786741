import React from 'react'
import styled from 'styled-components'
import variables from 'styles/_variables.scss';

export default function ImageCTA(props) {
	return (
		<StyledButton  
			className={`${props.className} ${props.selected ? "selected" : ""}`}
			onClick={props.handleClick}
			tabIndex="0">
			<ContentWrapper tabIndex="-1">
				<Text>{props.text}</Text>
				<Image src={props.image}/>
			</ContentWrapper>
		</StyledButton>
	)
}

const StyledButton = styled.button`
	position: relative;
	margin: 6px 0px;
	border-radius: 5px;
	transition: box-shadow 0.2s ease-in-out;
	box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px ${variables.grey3};
	background-color: ${variables.white};
	box-sizing: border-box;
	filter: grayscale(100%);
	overflow: hidden;
	@media (min-width: 720px) {
		margin: 0px 20px;
	}
	@media (min-width: 1280px) {
		margin: 0px 40px;
	}

	&:hover {
		cursor: pointer;
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
	}
	&:focus {
		border: solid 2px ${variables.captainBlue};
	}
	&.selected {
		filter: none;
		border: solid 2px ${variables.paleBlue};
		background-color: ${variables.aliceBlue};
		p {
			font-weight: 500;
			letter-spacing: -0.1px;
			color: ${variables.darkBlue};
		}
		&:focus {
			box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
			border: solid 2px ${variables.captainBlue};
		}
	}
`;

const ContentWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-start;
`;

const Text = styled.p`
	position: absolute;
	top: 16px;
	left: 50%;
	transform: translate(-50%, 0);
	font-family: museo-sans;
	font-size: 18px;
	font-weight: 300;
	line-height: 1.17;
	color: ${variables.brightBlue};
	@media (min-width: 720px) {
		top: 24px;
		font-size: 21px;
		line-height: 1.14;
	}
}
`;

const Image = styled.img`
	max-width:100%;
	max-height:100%;
`;