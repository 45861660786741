import React, { useState, useEffect } from 'react';
import 'App.scss';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { setNoIndex, removeNoIndex } from 'utils/SeoUtils'
import Footer from 'components/structure/Footer/Footer.js';
import LandingPage from 'components/content/LandingPage/LandingPage.js';
import ResultsPage from 'components/content/ResultsPage/ResultsPage.js';
import DiscoverForm from 'components/content/DiscoverForm/DiscoverForm.js';
import CollectionForm from 'components/content/CollectionForm/CollectionForm.js'

function App() {
	const [isResultsPage, setIsResultsPage] = useState(false)
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [myScholasticModal, setMyScholasticModal] = useState({ isOpen: false, openedFrom: ''});
	const [contractIdModal, setContractIdModal] = useState({ isOpen: false, openedFrom: ''});

	let location = useLocation()

	useEffect(() => {
		if(location.pathname === '/results'){
			setIsResultsPage(true);
			setNoIndex();
		} else {
			setIsResultsPage(false);
			removeNoIndex();
		}
	}, [location])

	return (
		<div className={`App ${isResultsPage ? 'isResultsPage' : ''}`}>
			<Switch>
				<Route
					exact
					path='/'
					render={(props) => (
						<LandingPage
							{...props}
							isSignedIn={isSignedIn}
							setIsSignedIn={setIsSignedIn}
							myScholasticModal={myScholasticModal}
							setMyScholasticModal={setMyScholasticModal}
							contractIdModal={contractIdModal}
							setContractIdModal={setContractIdModal}
						/>
					)} />
				<Route
					path='/results'
					render={(props) => (
						<ResultsPage
							{...props}
							isSignedIn={isSignedIn}
							setIsSignedIn={setIsSignedIn}
							myScholasticModal={myScholasticModal}
							setMyScholasticModal={setMyScholasticModal}
							contractIdModal={contractIdModal}
							setContractIdModal={setContractIdModal}
							/>
					)} />
				<Route path='/discover' component={DiscoverForm} />
				<Route path='/collection' component={CollectionForm} />
				<Route path='/*'>
					<Redirect to="/"/>
				</Route>
			</Switch>
			<Footer />
		</div>
	);
}

export default App;
