import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import './Nav.scss'
import scholasticLogo from 'images/scholastic-logo-white-outline.svg'
import bookWizardLogoMobile from 'images/bw-logo-white-mobile.svg'
import bookWizardLogoDesktop from 'images/bw-logo-white-desktop.svg'
import { ConfigContext } from 'providers/ConfigContext';
import Profile from 'components/content/Profile/Profile.js';
import Cart from 'components/content/Cart/Cart.js';
import Cookies from 'js-cookie';

export default function Nav(props) {
	const configContext = useContext(ConfigContext);

	let tsoOrigin = configContext.tso.origin;
	let isMobile = window.matchMedia('(max-width: 719px)').matches;
	let scrollTopNav = isMobile ? '0px' : '-94px';
	let default_urls = {
		tso: "/teachers-ecommerce/teacher/tsohomepage.html",
		sso: "/parent-ecommerce/parent-store.html",
		cos: "/teachers-ecommerce/cos/sample-order-homepage.html",
		lp: "/teachers-ecommerce/lp/lppartner.html",
		nat: "/teachers-ecommerce/rif/rifpartner.html",
		nys: "/teachers-ecommerce/nys/nyspartner.html",
		face: "/teachers-ecommerce/face/facepartner.html",
		rif: "/teachers-ecommerce/rif/rifpartner.html",
		ror: "/teachers-ecommerce/ror/rorpartner.html"
	};
	let partnerCookie = Cookies.get('SPS_PTNR');
	let storePath = default_urls['tso'];
	
	if (partnerCookie) {
		storePath = default_urls[partnerCookie.toLocaleLowerCase()];
	}
	return (
		<nav className={`globalnav ${props.hideOnScroll ? '' : 'scroll-up'}`} style={{top: `${props.hideOnScroll ? '' : scrollTopNav}`}}>
			<a className="globalnav__scholastic" href={tsoOrigin + storePath}>
					<img src={scholasticLogo} className="globalnav__scholastic-logo" alt="Scholastic Logo" tabIndex="0"/>
			</a>
			<div className="globalnav__book-wizard">
				<Link to="/" className="globalnav__book-wizard-logo">
					<img src={bookWizardLogoMobile} srcSet={`${bookWizardLogoDesktop} 720w`} alt="Book Wizard Logo" tabIndex="0"/>
				</Link>
				<Profile
					isSignedIn={props.isSignedIn}
					myScholasticModal={props.myScholasticModal}
					setMyScholasticModal={props.setMyScholasticModal}
					contractIdModal={props.contractIdModal}
					setContractIdModal={props.setContractIdModal}
					onSignInChange={props.setIsSignedIn}/>
				<Cart
					isSignedIn={props.isSignedIn}
					setMyScholasticModal={props.setMyScholasticModal}
					setContractIdModal={props.setContractIdModal}
					addToCartItem={props.addToCartItem}
					resetCart={props.resetCart}
					hideOnScroll={props.hideOnScroll}
					d={props.d}
					handleCartChange={props.handleCartChange} />
			</div>
		</nav>
	)
}