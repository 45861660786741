import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'providers/ConfigContext';
import { BrowserRouter } from 'react-router-dom';
import 'index.scss';
import App from 'App';
import { initDumbleData } from 'DumbleData.js';

initDumbleData();

ReactDOM.render(
	<React.StrictMode>
		<ConfigProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ConfigProvider>
	</React.StrictMode>,
  document.getElementById('root')
);
