import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import { ConfigContext } from 'providers/ConfigContext';
import SolidButton from 'components/content/Button/SolidButton';
import CloseButtonIcon from 'images/close-button.svg';
import './SaveToWishlistAlert.scss'

/** Displays an alert when a user adds something to their wishlist,
 *  along with an overlay
 */
function SaveToWishlistAlert(props) {
	const configContext = useContext(ConfigContext)

	return (
		<div className={`wishlistAlertOverlay ${props.hiddenClass} ${!props.hideOnScroll ? "topAligned" : ""}`}>
			<div className="saveToWishlistAlert">
				<div className="alertContent grid-layout">
					<p className="alertHeading">Item saved to Wishlist</p>
					<input
						className="alertClose" 
						type="image"
						src={CloseButtonIcon}
						onClick={props.handleAlertClose}
						alt="Close Wishlist Alert">
					</input>
					<div className="alertDetails">
						<img
							className="productImage"
							src={props.product.image}
							alt={`${props.product.title} Cover`}>
						</img>
						<div className="productDetails">
							<p className="productFormat">{props.product.format}</p>
							<p className="productTitle">{props.product.title}</p>
							<p className="productPrice">${props.product.displayPrice}</p>
						</div>
					</div>
					<SolidButton
						className="viewWishlistButton"
						text="VIEW YOUR WISHLIST"
						handleClick={() => window.open(`${configContext.tso.demandware}/Wishlist-Show`)}/>
				</div>
			</div>
		</div>
	)
}

/** Takes in a function to close the alert, a string specifying
 *  the class if the alert is active, and an object that contains 
 *  information about the product being displayed
 */
SaveToWishlistAlert.propTypes = {
	hiddenClass: PropTypes.string.isRequired,
	handleAlertClose: PropTypes.func.isRequired,
	hideOnScroll: PropTypes.bool.isRequired,
	product: PropTypes.shape({
		image: PropTypes.string,
		title: PropTypes.string,
		format: PropTypes.string,
		displayPrice: PropTypes.number
	})
}

export default SaveToWishlistAlert