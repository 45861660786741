import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import './CollectionCard.scss';
import dfyIconMobile from 'images/build-collection-for-you-mobile.svg';
import dfyIconDesktop from 'images/build-collection-for-you-desktop.svg';
import SolidButton from 'components/content/Button/SolidButton.js';

export class CollectionCard extends Component {
	constructor() {
		super();
		this.launchCollectionModal = this.launchCollectionModal.bind(this);
	}

	launchCollectionModal() {
		window._satellite.track('generate-collection-begin');
		this.props.history.push({pathname: '/collection'});
	}

	render() {
		return (
			<div className="collectionCard">
				<img
					src={dfyIconMobile}
					srcSet={`${dfyIconDesktop} 720w`}
					alt="Glasses Icon"
					className="discoverCard__glasses-icon">
				</img>
				<div className="discoverCard__title"><span>Let Us Build a</span> Collection for You</div>
				<div className="discoverCard__text">
					<span>Just tell us what you need,</span> <span>and we'll generate a</span> collection instantly!
				</div>
				<SolidButton text="GET RECOMMENDATIONS" handleClick={this.launchCollectionModal}/>
			</div>
		)
	}
}

export default withRouter(CollectionCard)