import React from 'react'
import styled from 'styled-components';
import './totalCTA.scss';
import TextField from '@material-ui/core/TextField';
import variables from 'styles/_variables.scss';

const min = 0;

export default function TotalCTA(props) {
	return (
		<StyledButton
			className={`${props.className} ${props.selected ? "selected" : ""}`}
			onClick={props.handleClick}
			tabIndex="0">
			<ContentWrapper tabIndex="-1">
				<Text>{props.text}</Text>
				<InputWrapper tabindex="-1">
					<Msg className="msg">{props.msg}</Msg>
					<TextField
							type="number"
							variant="outlined"
							value={props.value}
							placeholder={props.placeholder}
							margin="normal"
							inputProps={{ min }}
							onChange={(e) => {
								var value = parseInt(e.target.value, 10);

								if (value < min) value = min;
								e.target.value = value;

								props.handleChange(e);
							}}
							/>
				</InputWrapper>
			</ContentWrapper>
		</StyledButton>
	)
}

const StyledButton = styled.button`
	position: relative;
	margin: 6px 0px;
	border-radius: 5px;
	transition: box-shadow 0.2s ease-in-out;
	box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px ${variables.grey3};
	background-color: ${variables.white};
	box-sizing: border-box;
	filter: grayscale(100%);
	overflow: hidden;
	@media (min-width: 1280px) {
		margin: 0px 25px;
	}

	&:hover {
		cursor: pointer;
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
	}
	&:focus {
		border: solid 2px ${variables.captainBlue};
	}
	&.selected {
		filter: none;
		border: solid 2px ${variables.paleBlue};
		background-color: ${variables.aliceBlue};
		p {
			font-weight: 500;
			letter-spacing: -0.1px;
			color: ${variables.darkBlue};
		}
		&:focus {
			box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
			border: solid 2px ${variables.captainBlue};
		}
	}
`;

const ContentWrapper = styled.div`
	height: 100%;
	width: 100%;
	min-height: 260px;
	padding: 48px 0;
	@media (min-width: 720px) {
		padding: 58px 0;
	}
`;

const InputWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	width: fit-content;
`;

const Text = styled.p`
	font-family: museo-sans;
	font-size: 18px;
	font-weight: 300;
	line-height: 1.17;
	margin-bottom: 47px;
	color: ${variables.brightBlue};
	@media (min-width: 720px) {
		font-size: 21px;
		line-height: 1.14;
	}
`;

const Msg = styled.p`
	font-family: museo-sans;
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: 8px;
	color: ${variables.grey1};
`;