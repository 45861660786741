import React from 'react'
import styled from 'styled-components'
import variables from 'styles/_variables.scss';

export default function FilterCTA(props) {
	return (
		<StyledButton 
			onClick={props.handleClick}
			tabIndex="0" >
			<ContentWrapper tabIndex="-1">
				<Text>{props.text}</Text>
			</ContentWrapper>
		</StyledButton>
	)
}

const StyledButton = styled.button`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	align-self: normal;
	height: 40px;
	margin: 8px 6px;
	border-radius: 4px;
	padding: 0px 10px;
	white-space: nowrap;
	border: solid 1px ${variables.paleBlue};
	background-color: ${variables.white};
	box-sizing: border-box;
	@media (min-width: 720px) {
		&:focus {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	@media (max-width: 719px) {
		&:focus {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	&:hover {
		cursor: pointer;
		background-color: ${variables.aliceBlue};
		p {
			color: ${variables.darkBlue};
		}
	}
	&:focus {
		border: solid 2px ${variables.captainBlue};
		background-color: ${variables.aliceBlue};
		p {
			color: ${variables.darkBlue};
		}
	}
	&:active{
		background-color: ${variables.pastelBlue};
		p {
			color: ${variables.darkBlue};
		}
	}
`;

const ContentWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
`;

const Text = styled.p`
	font-family: museo-sans;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	text-align: center;
	color: ${variables.captainBlue};
	margin: 0px;
	hyphens: auto;
	pointer-events: none;
	@media (max-width: 719px) {
		font-size: 13px;
	}
`;