import React, { Component } from 'react'
import './BookCard.scss'
import { ConfigContext } from 'providers/ConfigContext';
import SolidButton from 'components/content/Button/SolidButton';
import OutlineButton from 'components/content/Button/OutlineButton';
import BookALikeButton from './BookALikeButton/BookALikeButton';
import TrashButton from './TrashButton/TrashButton';
import checkMarkRed from 'images/check-mark-red.svg';
import Cookies from 'js-cookie';
import ReactHtmlParser from 'react-html-parser';
import { isWishlistEnabled } from 'utils/Wishlist';

export class BookCard extends Component {
	static contextType = ConfigContext;
	constructor(props) {
		super(props);
		this.generateFacet = this.generateFacet.bind(this);
		this.generateContributorLink = this.generateContributorLink.bind(this);
		this.generateContributorsLink = this.generateContributorsLink.bind(this);
		this.generateGrade = this.generateGrade.bind(this);
		this.generateDisplayPrice = this.generateDisplayPrice.bind(this);
		this.formatGuidedReadingValue = this.formatGuidedReadingValue.bind(this);
	}

	generateFacet(facetType) {
		let facets = this.props.facets
		let facetValue = ''
		if(facets !== ''){
			let facetValues = facets.filter(facet => facet.name === facetType).map(filteredFacet => filteredFacet.values)[0]
			if(facetValues){
				if(facetValues.length === 1){
					facetValue = facetValues[0]
				}
				else if(facetValues.length > 1){
					facetValue = Math.min(...facetValues) + "–" + Math.max(...facetValues)
				}
			}
			if(facetType==="lexileLevel"){
				let lexileCode = facets.filter(facet => facet.name === 'lexileCode').map(filteredFacet => filteredFacet.values)[0]
				if(lexileCode){
					lexileCode = lexileCode[0].substring(0,2)
					facetValue = lexileCode + facetValue + "L"
				}
			}
		}
		return facetValue
	}

	formatGuidedReadingValue(value) {
		const A = 'A'.charCodeAt(0);
		if (value === 27)
			{ return 'Z+'; }
		return String.fromCharCode(A + (value - 1));
	}

	generateContributorsLink(contributors) {
		let contribs = contributors.split(';');
		let link = '';
		let store = 'teacher';
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie && partnerCookie !== 'TSO') {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
			store = partnerCookie.toLowerCase();
		}
		if (contribs.length > 1) {
			contribs.map(c => link += `<a href="${this.context.tso.origin}/teachers-ecommerce/${store}/search-results.html?search=1&text=${c}" target="_blank" rel="noopener noreferrer">${c}</a> `);
		} else {
			link = `<a href="${this.context.tso.origin}/teachers-ecommerce/${store}/search-results.html?search=1&text=${contribs[0]}" target="_blank" rel="noopener noreferrer">${contribs[0]}</a>`;
		}
		return ReactHtmlParser(link);
	}

	generateContributorLink(contributorType) {
		let store = 'teacher';
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie && partnerCookie !== 'TSO') {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
			store = partnerCookie.toLowerCase();
		}
		return this.context.tso.origin + `/teachers-ecommerce/${store}/search-results.html?search=1&text=` + this.props[contributorType]
	}

	generateGrade() {
		return this.props.grade.replace(' - ', '–')
	}

	generateDisplayPrice() {
		return this.props.displayPrice.toFixed(2)
	}

	render() {
		let store = 'tso';
		let partnerCookie = Cookies.get('SPS_PTNR');
		if (partnerCookie) {
			if (partnerCookie === 'NAT') partnerCookie = 'RIF';
			store = partnerCookie.toLowerCase();
		}
		return (
			<div className="bookCard">
				<div className="bookCard--bookDetails">
					<a href={this.props.url} tabIndex="0" className="bookCard--cover-link" target="_blank" rel="noopener noreferrer">
						<img src={this.props.img} className="bookCard--cover" alt={this.props.title + ' Cover'}/>
					</a>
					<div className="bookCard--info">
						<p className="bookCard--format">{this.props.format}</p>
						<a href={this.props.url} className="bookCard--title" tabIndex="0" target="_blank" rel="noopener noreferrer" >{this.props.title}</a>
						<div className="bookCard--contributors">
							{this.props.author &&
								<p className="bookCard--author">By {this.generateContributorsLink(this.props.author)}</p>
							}
							{this.props.illustrator &&
								<p className="bookCard--illustrator">Illustrator {this.generateContributorsLink(this.props.illustrator)}</p>
							}
						</div>
						{/* Need a desktop and mobile version due to the way the container is organized */}
						{this.props.handleBookALikeClick && <BookALikeButton handleBookALikeClick={() => this.props.handleBookALikeClick(this.props.isbn)} type="desktop"/>}
					</div>
				</div>
				{this.props.handleBookALikeClick && <BookALikeButton handleBookALikeClick={() => this.props.handleBookALikeClick(this.props.isbn)} type="mobile"/>}
				<div className="bookCard--facets">
					<ul>
						{this.props.grade &&
							<li>
								<span className="bookCard--facetTitle">Grades</span>
								<span className="bookCard--facetValue">{this.generateGrade()}</span>
							</li>
						}
						{this.generateFacet('guidedReadingLevel') &&
							<li>
								<span className="bookCard--facetTitle">Guided Reading Level</span>
								<span className="bookCard--facetValue">{this.formatGuidedReadingValue(this.generateFacet('guidedReadingLevel'))}</span>
							</li>
						}
						{this.generateFacet('lexileLevel') &&
							<li>
								<span className="bookCard--facetTitle">Lexile<sup>®</sup> Measure</span>
								<span className="bookCard--facetValue">{this.generateFacet('lexileLevel')}</span>
							</li>
						}
						{this.generateFacet('draLevel') &&
							<li>
								<span className="bookCard--facetTitle">DRA Level</span>
								<span className="bookCard--facetValue">{this.generateFacet('draLevel')}</span>
							</li>
						}
						{this.generateFacet('acceleratedReaderLevel') &&
							<li>
								<span className="bookCard--facetTitle">ACR Level</span>
								<span className="bookCard--facetValue">{this.generateFacet('acceleratedReaderLevel')}</span>
							</li>
						}
					</ul>
				</div>
				<div className="bookCard--commerce">
					<div className="bookCard--price">
						{(this.props.displayPrice !== undefined && this.props.displayPrice !== null) && <p className="bookCard--listPrice">${this.generateDisplayPrice()}</p>}
						{this.props.listPrice > this.props.displayPrice ? <p className="bookCard--salePrice">${this.props.listPrice}</p>:''}
					</div>
					<SolidButton
						text={this.props.addToCartButtonLabel}
						className="bookCard--addButton"
						disabled={this.props.addToCartButtonLabel === "OUT OF STOCK"}
						handleClick={() => this.props.handleAddToCart(
							{
								isbn: this.props.isbn,
								img: this.props.img,
								title: this.props.title,
								displayPrice: this.props.displayPrice,
								listPrice: this.props.listPrice,
								salePrice: this.props.salePrice,
								author: this.props.author,
								format: this.props.format,
								guidedReadingLevel: this.formatGuidedReadingValue(this.generateFacet('guidedReadingLevel')),
								grades: this.generateGrade()
							}
						)}
					/>
					{ isWishlistEnabled(store) ? 
						(this.props.inWishlist ?
							<OutlineButton
								text="VIEW WISHLIST"
								icon={checkMarkRed}
								iconLeft
								className="saveToWishlistButton"
								handleClick={() => window.open(`${this.context.tso.demandware}/Wishlist-Show`)}
							/> :
							<OutlineButton
								text="SAVE TO WISHLIST"
								className="saveToWishlistButton"
								handleClick={() => this.props.handleWishlistClick({
									productId: this.props.productId,
									image: this.props.img,
									title: this.props.title,
									format: this.props.format,
									displayPrice: this.props.displayPrice,
									listPrice: this.props.listPrice,
									author: this.props.author,
									guidedReadingLevel: this.formatGuidedReadingValue(this.generateFacet('guidedReadingLevel')),
									grades: this.generateGrade(),
									isbn: this.props.isbn
								})}
							/>)
						: null
					}
					{this.props.handleDeleteBookCard &&
						<div className="bookCard--collectionItems">
							{this.props.quantity !== '' ? <span>Quantity <span>{this.props.quantity}</span></span> : ''}
							<TrashButton handleDeleteBookCard={() => this.props.handleDeleteBookCard(this.props.isbn)} />
						</div>
					}
				</div>
			</div>
		)
	}
}

export default BookCard