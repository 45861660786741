import React, { useRef, useEffect } from 'react';
import './BaseSlider.scss'
import 'antd/dist/antd.css';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Slider } from 'antd';


export default function BaseSlider (props){

	const { 
		formatValueFromSliderTick,
		sliderTickMarks,
		sliderTickRange,
		sliderTickIncrement,
		sliderDefaultThumbValues,
		selectedMinValue,
		setSelectedMinValue,
		selectedMaxValue,
		setSelectedMaxValue,
		getSliderTickPositionFromValue,
		formatValueForConstructor,
		renderLexileCodeDropdown,
		handleChangeValue
	} = props;

	const minTickValue = sliderTickRange[0];
	const maxTickValue = sliderTickRange[1];
	const minDefaultThumbValue = sliderDefaultThumbValues[0];
	const maxDefaultThumbValue = sliderDefaultThumbValues[1];
	const dropdownOptions = generateDropdownOptions(minTickValue,maxTickValue);

	function generateDropdownOptions(min,max) {
		let optionsArray = [];
		while(min <= max){
			optionsArray.push(formatValueFromSliderTick(min));
			min += sliderTickIncrement;
		}
		return optionsArray;
	}

	const minInputRef = useRef();
	const maxInputRef = useRef();

	/* handlers for when user types in full slider value and
	   hits return instead of selecting from dropdown options */
	useEffect(() => {
		const updateMinSelected = (e) => {
			let newValue = e.target.defaultValue.toUpperCase();
			if(e.which === 13 && dropdownOptions.indexOf(newValue) > -1){
				setSelectedMinValue(getSliderTickPositionFromValue(newValue));
				handleChangeValue('readingLevelMin', formatValueForConstructor(getSliderTickPositionFromValue(newValue)));
				handleChangeValue('readingLevelMax', formatValueForConstructor(selectedMaxValue));
				handleChangeValue('readingLevelMinDisplay', newValue);
				handleChangeValue('readingLevelMaxDisplay', formatValueFromSliderTick(selectedMaxValue));
			}
		}

		let currentMinInputRef = minInputRef.current;
		currentMinInputRef.addEventListener("keydown", updateMinSelected);
		return () => currentMinInputRef.removeEventListener("keydown", updateMinSelected);
	});

	useEffect(() => {
		const updateMaxSelected = (e) => {
			let newValue = e.target.defaultValue.toUpperCase();
			if(e.which === 13 && dropdownOptions.indexOf(newValue) > -1){
				setSelectedMaxValue(getSliderTickPositionFromValue(newValue));
				handleChangeValue('readingLevelMax', formatValueForConstructor(getSliderTickPositionFromValue(newValue)));
				handleChangeValue('readingLevelMin', formatValueForConstructor(selectedMinValue));
				handleChangeValue('readingLevelMaxDisplay', newValue);
				handleChangeValue('readingLevelMinDisplay', formatValueFromSliderTick(selectedMinValue));
			}
		}

		let currentMaxInputRef = maxInputRef.current;
		currentMaxInputRef.addEventListener("keydown", updateMaxSelected);
		return () => currentMaxInputRef.removeEventListener("keydown", updateMaxSelected);
	});


	function renderMinDropdown() {
		return (
			<Autocomplete
				id="minDropdown"
				autoComplete={true}
				noOptionsText="No Results"
				onChange={(event, newValue) => {
					if(newValue !== null){
						setSelectedMinValue(getSliderTickPositionFromValue(newValue))
						handleChangeValue('readingLevelMin', formatValueForConstructor(getSliderTickPositionFromValue(newValue)))
						handleChangeValue('readingLevelMax', formatValueForConstructor(selectedMaxValue))
						handleChangeValue('readingLevelMinDisplay', newValue)
						handleChangeValue('readingLevelMaxDisplay', formatValueFromSliderTick(selectedMaxValue))
					}
				}}
				value={formatValueFromSliderTick(selectedMinValue)}
				style={{ width: 160 }}
				options={dropdownOptions}
				getOptionLabel={(option) => option}
				renderInput={(params) => (
					<TextField 
						{...params}
						label={"Min Reading Level"}
						variant="outlined"
						inputRef={minInputRef}
						margin="normal"/>
				)}
				renderOption={(option, { inputValue }) => {
					const matches = match(option, inputValue);
					const parts = parse(option, matches);

					return (
						<div>
						{parts.map((part, index) => (
							<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
							{part.text}
							</span>
							))}
						</div>
					);
				}}
			/>
		);
	}

	function renderMaxDropdown() {
		return (
			<Autocomplete
				id="maxDropdown"
				autoComplete={true}
				noOptionsText="No Results"
				onChange={(event, newValue) => {
					if(newValue !== null){
						setSelectedMaxValue(getSliderTickPositionFromValue(newValue))
						handleChangeValue('readingLevelMax', formatValueForConstructor(getSliderTickPositionFromValue(newValue)) )
						handleChangeValue('readingLevelMin', formatValueForConstructor(selectedMinValue))
						handleChangeValue('readingLevelMaxDisplay', newValue)
						handleChangeValue('readingLevelMinDisplay', formatValueFromSliderTick(selectedMinValue))
					}
				}}
				value={formatValueFromSliderTick(selectedMaxValue)}
				style={{ width: 160 }}
				options={dropdownOptions}
				getOptionLabel={(option) => option}
				renderInput={(params) => (
					<TextField 
						{...params}
						label={"Max Reading Level"}
						variant="outlined"
						inputRef={maxInputRef}
						margin="normal"/>
				)}
				renderOption={(option, { inputValue }) => {
					const matches = match(option, inputValue);
					const parts = parse(option, matches);

					return (
						<div>
						{parts.map((part, index) => (
							<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
							{part.text}
							</span>
							))}
						</div>
					);
				}}
			/>
		);
	}
	return (
		<div className="slider-wrapper">
			<div className={`options-wrapper mobile ${props.sliderType}`}>
				{renderLexileCodeDropdown ? renderLexileCodeDropdown() : null}
			</div>
			<Slider 
				range 
				min={minTickValue}
				max={maxTickValue}
				step={sliderTickIncrement}
				marks={sliderTickMarks}
				tipFormatter={formatValueFromSliderTick} 
				defaultValue={[minDefaultThumbValue, maxDefaultThumbValue]}
				onChange={(newValue) => {
					setSelectedMinValue(newValue[0]);
					setSelectedMaxValue(newValue[1]);
					handleChangeValue('readingLevelMin', formatValueForConstructor(newValue[0]));
					handleChangeValue('readingLevelMax', formatValueForConstructor(newValue[1]));
					handleChangeValue('readingLevelMinDisplay', formatValueFromSliderTick(newValue[0]));
					handleChangeValue('readingLevelMaxDisplay', formatValueFromSliderTick(newValue[1]));
				}}
				value={[selectedMinValue,selectedMaxValue]}
				getTooltipPopupContainer={() => document.querySelector(".ant-slider")}
			/>
			<div className={`options-wrapper ${props.sliderType}`}>
				{renderLexileCodeDropdown ? renderLexileCodeDropdown() : null}
				{renderLexileCodeDropdown ? <div className="options-spanner no-mobile"></div> : null}
				{renderMinDropdown()}
				<div className="options-spanner">
					<div>to</div>
				</div>
				{renderMaxDropdown()}
			</div>
		</div>
	)
}