import Cookies from 'js-cookie';

export function initDumbleData() {
	let sps_ud = Cookies.get('SPS_UD');

	window.dumbleData = {
			page: {
				pageInfo: {
					domain: document.domain,
					title: '',
					isError: false,
					pageType: ''
				}
			},
			siteInfo: {
				environment: process.env.REACT_APP_ENV,
				audience: 'teacher',
				language: 'en',
				name: 'BookWizard'
			},
			user: {
				loginStatus: sps_ud ? true : false,
				profile: {
					userType: '',
					SPSID: sps_ud ? sps_ud.split('|')[0] : "",
					grade: []
				}
			},
			cart: {
				itemsCount: 0,
				price: {
					basePrice: 0,
					currency: 'USD'
				}
			},
			cartItems: [],
			basketId: '',
			results: {
				totalNum: '',
				facets: [],
				sort: ''
			},
			search: {
				input: '',
				keyWord: '',
				numResults: 0,
				sort: '',
				type: '',
				bookWizard: {}
			},
			product: {
				author: '',
				format: '',
				guidedReadingLevel: '',
				highGrade: '',
				lowGrade: '',
				productID: '',
				title: '',
				listPrice: 0,
				displayPrice: 0
			},
			wishlist: {
				author: '',
				format: '',
				guidedReadingLevel: '',
				highGrade: '',
				lowGrade: '',
				productID: '',
				title: '',
				listPrice: 0,
				displayPrice: 0
			}
	};
}

export function setPageType(pageType) {
	window.dumbleData.page.pageInfo.pageType = pageType;

	if (!pageType.includes("Results")) {
		removeSearchAndResults();
	} else if (!window.dumbleData.hasOwnProperty('search') && pageType.includes("Results")) {
		addSearchAndResults();
	}
}

export function setTitle(title) {
	window.dumbleData.page.pageInfo.title = title;
}

/** Runs the _satellite.track function to indicate a new
 *  page has loaded, runs on all book wizard pages (landing, discover, results, etc.)
 */
export function trackPageLoad() {
	window._satellite.track('BookWizard:PageLoad');
}

export function trackAddToCart() {
	window._satellite.track('BookWizard:AddToCart');
}

export function trackAddToWishlist() {
	window._satellite.track('BookWizard:AddToWishlist');
}

export function trackHomepageSearch() {
	window._satellite.track('BookWizard:Homepage_Search');
}

export function trackDiscoverResults() {
	window._satellite.track('BookWizard:Discover_Results');
}

export function trackResultspageSearch() {
	window._satellite.track('BookWizard:General_Search');
}

/** Updates the data layer and makes a satellite call whenever a
 *  book-a-like button is clicked
 */
export function trackBookALikeSearch(resultsLength, bookTitle) {
	let ddResults = window.dumbleData.results
	ddResults.totalNum = resultsLength
	ddResults.facets = ["No Filters"]
	ddResults.sort = "relevance"

	let ddSearch = window.dumbleData.search
	ddSearch.input = ""
	ddSearch.keyWord = bookTitle
	ddSearch.numResults = resultsLength
	ddSearch.sort = "relevance"
	ddSearch.type = "book-a-like"

	window._satellite.track('BookWizard:Bookalike_Search')
}

export function setUserType(userType) {
	window.dumbleData.user.profile.userType = userType;
}

export function setGrade(gradeBand) {
	window.dumbleData.user.profile.grade = gradeBand;
}

export function setCartInfo(info) {
	window.dumbleData.cart.itemsCount = info.totalCartItems;
	window.dumbleData.cart.price.basePrice = info.orderSubTotal;
	window.dumbleData.basketId = (info.totalCartItems > 0) ? info.basketID : '';

	window.dumbleData.cartItems = [];

	info.cartItems.forEach((item, itemIndex) => {
		let element = {};
		element.productId = item.orderItemId;
		element.price = item.yourPrice;
		element.salesPrice = item.listPrice;
		element.quantity = item.qty;
		element.producType = item.productType;
		window.dumbleData.cartItems.push({ [`item${itemIndex}`]: element });
	});

}

export function setProductInfo(info,category) {
	window.dumbleData[category].author = info.author;
	window.dumbleData[category].format = info.format;
	window.dumbleData[category].guidedReadingLevel = info.guidedReadingLevel;
	window.dumbleData[category].lowGrade = info.grades.split("–")[0];
	window.dumbleData[category].highGrade = info.grades.split("–")[1];
	window.dumbleData[category].productID = info.isbn;
	window.dumbleData[category].displayPrice = parseFloat(info.displayPrice.toFixed(2));
	window.dumbleData[category].title = info.title;
	window.dumbleData[category].listPrice = info.listPrice ? parseFloat(info.listPrice.toFixed(2)) : '';
}

export function setResults(data, handleQuery) {
	window.dumbleData.results.totalNum = data.response.total_num_results;
	window.dumbleData.results.facets = [];
	window.dumbleData.results.sort = data.request.sort_by;

	if (typeof handleQuery === 'string') {
		window.dumbleData.results.facets.push("No Filters");
	}
	else{
		for (let i = 0; i <= 5; i++) {
			if (i === 1 && handleQuery[0] === 'guidedReadingLevel') {
				window.dumbleData.results.facets = window.dumbleData.results.facets.concat(
					formatGuidedReadingValue(handleQuery[i].split('-')[0]) + '-' + formatGuidedReadingValue(handleQuery[i].split('-')[1])
				);
			} else if(handleQuery[i] !== '' && handleQuery[i] !== '-') {
				window.dumbleData.results.facets = window.dumbleData.results.facets.concat(handleQuery[i]);
			}
		}
	}

	setNumResultsAndSort(data);
}

/** Sets the dumbleData property search.type */
export function setSearchType(type) {
	window.dumbleData.search.type = type
}

export function setSearchTypeInputKeyword(searchType, searchTerm, typedTerm = 0){
	if (searchType === 'user-entered') {
		window.dumbleData.search.type = searchType;
		window.dumbleData.search.input = searchTerm;
		window.dumbleData.search.keyWord = searchTerm;
	} else {
		window.dumbleData.search.type = searchType;
		window.dumbleData.search.input = typedTerm;
		window.dumbleData.search.keyWord = searchTerm;
	}
}

function setNumResultsAndSort(data){
	window.dumbleData.search.numResults = data.response.total_num_results;
	window.dumbleData.search.sort = data.request.sort_by;
}

export function setCollectionData(data, browseQuery) {
	window.dumbleData.search.bookWizard.budget = browseQuery.budget;
	window.dumbleData.search.bookWizard.bookBudget = browseQuery.books;
	window.dumbleData.search.bookWizard.quantityPerBook = browseQuery.quantity;
	window.dumbleData.search.bookWizard.maxSpendPerBook = browseQuery.maxPrice;

	if(browseQuery.oosToggle === '') {
		window.dumbleData.search.bookWizard.showOutOfStock = true;
	}
	else {
		window.dumbleData.search.bookWizard.showOutOfStock = browseQuery.oosToggle;
	}

	window.dumbleData.search.bookWizard.gradeReadingSelection = browseQuery.level;

	if(browseQuery.level === 'grade') {
		let selectedGrades = [];
		Object.keys(browseQuery.gradeLevels).forEach(grade => {
			if(browseQuery.gradeLevels[grade].selected) {
				selectedGrades.push(grade);
			}
		});
		window.dumbleData.search.bookWizard.gradeLevels = selectedGrades;
	}
	else {
		window.dumbleData.search.bookWizard.levelSystem = browseQuery.readingLevelSystem;
		window.dumbleData.search.bookWizard.minLevel = browseQuery.readingLevelMin;
		window.dumbleData.search.bookWizard.maxLevel = browseQuery.readingLevelMax;
		window.dumbleData.search.bookWizard.lexileCode = browseQuery.lexileCode;
	}

	let selectedCategories = [];
	if(browseQuery.categories.fiction.selected) {
		selectedCategories.push('Fiction')
	}
	if(browseQuery.categories['non-fiction'].selected) {
		selectedCategories.push('Non-Fiction');
	}
	window.dumbleData.search.bookWizard.categories = selectedCategories;

	let selectedSubjects = [];
	Object.keys(browseQuery.subjects).forEach(subject => {
		if(browseQuery.subjects[subject].selected) {
			selectedSubjects.push(subject);
		}
	});
	window.dumbleData.search.bookWizard.subjects = selectedSubjects;

	let selectedGenres = [];
	Object.keys(browseQuery.genres).forEach(genre => {
		if(browseQuery.genres[genre].selected) {
			selectedGenres.push(genre);
		}
	});
	window.dumbleData.search.bookWizard.genres = selectedGenres;
}

function removeSearchAndResults() {
	delete window.dumbleData.results;
	delete window.dumbleData.search;
}

export function addSearchAndResults() {
	window.dumbleData.results = { totalNum: '', facets: [], sort: '' };
	window.dumbleData.search = { input: '', keyWord: '', numResults: 0, sort: '', type: '', bookWizard: {} };
}

function formatGuidedReadingValue(value) {
	const A = 'A'.charCodeAt(0);
	if (value === '27')
		{ return 'Z+'; }
	return String.fromCharCode(A + (value - 1));
}