import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import * as dumbleData from 'DumbleData';
import produce from 'immer';
import './CollectionForm.scss'
import BookWizardIcon from 'images/bw-logo-blue.svg';
import CloseButtonIcon from 'images/close-button.svg';
import LightningIcon from 'images/lightning-icon.svg';
import SolidButton from 'components/content/Button/SolidButton.js';
import OutlineButton from 'components/content/Button/OutlineButton.js';
import BudgetPage from 'components/content/CollectionForm/BudgetPage/BudgetPage.js';
import AboutBooksPage from 'components/content/CollectionForm/AboutBooksPage/AboutBooksPage.js';
import LevelPage from 'components/content/CollectionForm/LevelPage/LevelPage.js';
import GradePage from 'components/content/CollectionForm/GradePage/GradePage.js';
import LanguagePage from 'components/content/CollectionForm/LanguagePage/LanguagePage.js';
import ReadingLevelPage from 'components/content/CollectionForm/ReadingLevelPage/ReadingLevelPage.js';
import CategoryPage from 'components/content/CollectionForm/CategoryPage/CategoryPage.js';
import SubjectPage from 'components/content/CollectionForm/SubjectPage/SubjectPage.js';
import GenrePage from 'components/content/CollectionForm/GenrePage/GenrePage.js';

export class CollectionForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			step: 1,
			totalType: '',
			books: '',
			budget: '',
			quantity: '',
			maxPrice: '',
			oosToggle: '',
			language: '',
			level: '',
			readingLevelSystem: '',
			lexileCode: '',
			readingLevelMin: '',
			readingLevelMax: '',
			readingLevelMinDisplay: '',
			readingLevelMaxDisplay: '',
			slider: {
				"showSlider" : {selected: false},
			},
			gradeLevels: {
				"prek" : {display_name: "Pre-K", selected: false},
				"kindergarten" : {display_name: "Kindergarten", selected: false},
				"grade-1" : {display_name: "Grade 1", selected: false},
				"grade-2" : {display_name: "Grade 2", selected: false},
				"grade-3" : {display_name: "Grade 3", selected: false},
				"grade-4" : {display_name: "Grade 4", selected: false},
				"grade-5" : {display_name: "Grade 5", selected: false},
				"grade-6" : {display_name: "Grade 6", selected: false},
				"grade-7" : {display_name: "Grade 7", selected: false},
				"grade-8" : {display_name: "Grade 8", selected: false},
				"grade-9" : {display_name: "Grade 9", selected: false},
				"grade-10" : {display_name: "Grade 10", selected: false},
				"grade-11" : {display_name: "Grade 11", selected: false},
				"grade-12" : {display_name: "Grade 12", selected: false}
			},
			categories: {
				"fiction" : {display_name: "Fiction", selected: false},
				"non-fiction" : {display_name: "Nonfiction", selected: false}
			},
			languages: {},
			subjects: {},
			genres: {},
			cachedSubjectFacets: '',
			cachedGenreFacets: '',
			cachedLangugeFacets: '',
			isFilterUpdate: false
		}
	}

	componentDidMount() {
		if(this.props.history.location.state){
			this.setState(this.props.history.location.state);
		}
		dumbleData.setPageType('Collection');
		dumbleData.setTitle('BookWizard:Collection');
	}

	nextStep = () => {
		/* use nav buttons to toggle btw slider and slider
		selector in reading level system page */
		if(this.state.step === 1) {
			if(this.state.totalType === 'totalBudget') {
				window._satellite.track('generate-collection-set-budget', { budget: this.state.budget });
			}
			else if(this.state.totalType === 'totalBooks') {
				window._satellite.track('generate-collection-set-books', { books: this.state.books });
			}
			this.setState({ step: (this.state.step + 1) });
		}
		else if(this.state.step === 2) {
			let oosToggle = true;
			if(this.state.oosToggle === '') {
				oosToggle = true;
			}
			else {
				oosToggle = this.state.oosToggle;
			}
			window._satellite.track('generate-collection-multiple-copies-selection', {
				quantityPerBook: this.state.quantity,
				maxSpendPerBook: this.state.maxPrice,
				showOutOfStock: oosToggle
			});
			this.setState({ step: (this.state.step + 1) });
		}
		else if(this.state.step === 3) {
			let gradeReadingSelection = '';
			if(this.state.level === 'reading') {
				gradeReadingSelection = 'Reading Level';
			}
			else {
				gradeReadingSelection = 'Grade Level';
			}
			window._satellite.track('grade-reading-selection', {
				detail: gradeReadingSelection
			});
			this.setState({ step: (this.state.step + 1) });
		}
		else if(this.state.step === 4) {
			let levelSystem = this.state.readingLevelSystem;
			let detail = '';
			switch (levelSystem) {
				case 'guidedReadingLevel': detail = 'Guided Reading'; break;
				case 'lexileLevel': detail = 'Lexile Measure'; break;
				case 'draLevel': detail = 'Developmental Reading Assessment (DRA)'; break;
				case 'acceleratedReaderLevel': detail = 'Accelerated Reader (ACR)'; break;
				default: break;
			}
			
			if(this.state.level === 'grade') {
				let selectedGrades = [];
				Object.keys(this.state.gradeLevels).forEach(grade => {
					if(this.state.gradeLevels[grade].selected) {
						selectedGrades.push(grade);
					}
				});
				window._satellite.track('grade-range-selection', { selectedGrades });
				this.setState({ step: (this.state.step + 1) });
			}
			else if (this.state.readingLevelSystem !== ''
				&& !this.state.slider.showSlider.selected
				&& this.state.level === 'reading') {
				this.handleChangeCheckbox('slider','showSlider');	
				window._satellite.track('reading-level-system-selection', {
					detail
				});
			}
			else {
				let lexileCode = null;
				if(this.state.lexileCode !== '') {
					lexileCode = this.state.lexileCode;
				}
				window._satellite.track('reading-level-range-selection', {
					levelSystem: this.state.readingLevelSystem,
					minLevel: this.state.readingLevelMin,
					maxLevel: this.state.readingLevelMax,
					lexileCode: lexileCode
				});
				this.setState({ step: (this.state.step + 1) });
			}
		}
		else if (this.state.step === 5) {
			let selectedLanguages = [];
			Object.keys(this.state.languages).forEach(language => {
				if(this.state.languages[language].selected) {
					selectedLanguages.push(language);
				}
			});

			this.setState({ step: (this.state.step + 1) });
		}
		else if (this.state.step === 6) {
			let categorySelection = [];
			if(this.state.categories.fiction.selected) {
				categorySelection.push('Fiction');
			}
			if(this.state.categories['non-fiction'].selected) {
				categorySelection.push('Nonfiction');
			}
			window._satellite.track('category-selection', {
				detail: categorySelection
			});
			this.setState({ step: (this.state.step + 1) });
		}
		else if (this.state.step === 7) {
			let selectedSubjects = [];
			Object.keys(this.state.subjects).forEach(subject => {
				if(this.state.subjects[subject].selected) {
					selectedSubjects.push(subject);
				}
			});
			window._satellite.track('subject-selection', {
				detail: selectedSubjects
			});

			this.setState({ step: (this.state.step + 1) });
		}
		else if (this.state.step === 8) {
			let selectedGenres = [];
			Object.keys(this.state.genres).forEach(genre => {
				if(this.state.genres[genre].selected) {
					selectedGenres.push(genre);
				}
			});
			window._satellite.track('genre-selection', {
				detail: selectedGenres
			});
			window._satellite.track('generate-collection-complete');
			this.renderResultsPage();
		} else {
			this.setState({ step: (this.state.step + 1) });
		}
	};

	prevStep = () => {
		/* use nav buttons to toggle btw slider and slider
		selector in reading level system page */
		if(this.state.step === 4
			&& this.state.readingLevelSystem !== ''
			&& this.state.slider.showSlider.selected
			&& this.state.level === 'reading') {
			this.handleChangeCheckbox('slider','showSlider');
		} else {
			if(this.state.step === 1) {
				this.props.history.push({
					pathname: '/',
				});
			}
			this.setState({ step: (this.state.step - 1) });
		}
	};

	renderResultsPage = () => {
		let selectedTotalBooks = this.state.books;
		let selectedQuantityPerBook = this.state.quantity;
		let selectedPriceCap = this.state.maxPrice;
		let selectedReadingLevel = this.state.readingLevelSystem;
		let selectedReadingLevelRange = this.state.readingLevelMin + '-' + this.state.readingLevelMax;
		let selectedGrades = this.getSelectedFacets('gradeLevels');
		let selectedCategories = this.getSelectedFacets('categories');
		let selectedSubjects = this.getSelectedFacets('subjects');
		let selectedLanguages = this.getSelectedFacets('languages');
		let selectedGenres = this.getSelectedFacets('genres');
		let outofstockToggle = this.state.oosToggle;
		this.props.history.push({
			pathname: '/results',
			state: {
				browseQuery: this.state,
				browseFacets: [selectedTotalBooks, selectedQuantityPerBook, selectedPriceCap, selectedReadingLevel, selectedReadingLevelRange, selectedGrades, selectedCategories, selectedSubjects, selectedLanguages, selectedGenres],
				collectionSettings: [outofstockToggle],
				flow: 'collection-flow'
			}
		});
	}

	/* handle when user clicks close X before updating filters completely */
	renderLastResultsPage = () => {
		this.setState(this.props.history.location.state, () => {
			this.renderResultsPage();
		});
	}

	//Iterate facets and return only those with a selected property set to true
	getSelectedFacets = (facetName) => {
		return Object.entries(this.state[facetName])
				.filter(([key, value]) => value.selected)
				.map(facet => facet[0]);
	}

	handleChangeCheckbox = (key, value) => {
		this.setState(prevState => {
			return produce(prevState, draft => {
				draft[key][value].selected = !prevState[key][value].selected;
			})
		});
	}

	handleChangeRadio = (key, value) => {
		if(this.state[key] === value) {
			this.setState({ [key] : '' });
		} else {
			this.setState({ [key] : value });
		}
	}

	handleChangeValue = (key, value) => {
		this.setState({ [key] : value });
	}

	render() {
		const { totalType, budget, books, maxPrice, quantity, oosToggle, level, readingLevelSystem, slider, gradeLevels, categories, subjects, genres, lexileCode, readingLevelMin, readingLevelMax, readingLevelMinDisplay, readingLevelMaxDisplay, step, languages } = this.state;
		const values = { totalType, budget, books, maxPrice, oosToggle, quantity, level, readingLevelSystem, slider, gradeLevels, categories, subjects, genres, lexileCode, readingLevelMin, readingLevelMax, readingLevelMinDisplay, readingLevelMaxDisplay, languages};
		const sliderHeadings = {
			"lexileLevel": "Select a Lexile\xAE Measure or Range",
			"guidedReadingLevel": "Select a Guided Reading Level or Range",
			"draLevel": "Select a Developmental Reading Assessement (DRA) Level or Range",
			"acceleratedReaderLevel": "Select an Accelerated Reader (ACR) Level or Range",
		}
		let nextDisabled = false;
		let nextButtonText = step === 8 ? "VIEW RESULTS" : "NEXT";
		let nextButtonIcon = step === 8 ? LightningIcon : null;
		let optional = false;
		let currentPage;
		let pageHeading;

		// Switch statement to render page component based on step
		switch (step) {
			case 1:
				pageHeading = "Tell us a bit about your needs";
				nextDisabled = totalType === '' || (totalType === 'totalBudget' && budget === '') || (totalType === 'totalBooks' && books === '');
				currentPage =
					<BudgetPage
						nextStep={this.nextStep}
						handleChange={this.handleChangeValue}
						values={values}
					/>;
				break;
			case 2:
					pageHeading = "Do you need multiple copies of each book ?";
					optional = true;
					currentPage =
						<AboutBooksPage
							nextStep={this.nextStep}
							handleChange={this.handleChangeRadio}
							values={values}
						/>;
					break;
			case 3:
				pageHeading = "We can help you discover books by...";
				nextDisabled = this.state.level === ''
				currentPage =
					<LevelPage
						nextStep={this.nextStep}
						handleChange={this.handleChangeRadio}
						values={values}
					/>;
				break;
			case 4:
				if (this.state.level === 'grade') {
					pageHeading = "Select one or more Grade";
					nextDisabled = !Object.values(this.state.gradeLevels).some(grade => grade.selected);
					currentPage =
						<GradePage
							nextStep={this.nextStep}
							handleChange={this.handleChangeCheckbox}
							values={values}
						/>;
				} else if (this.state.level === 'reading') {
					pageHeading = this.state.slider.showSlider.selected ? sliderHeadings[this.state.readingLevelSystem] : "Select a Reading Level System";
					optional = this.state.slider.showSlider.selected;
					nextDisabled = this.state.readingLevelSystem === ''
					currentPage =
						<ReadingLevelPage
							nextStep={this.nextStep}
							handleChange={this.handleChangeRadio}
							handleChangeCheckbox={this.handleChangeCheckbox}
							handleChangeValue={this.handleChangeValue}
							values={values}
						/>;
				}
				break;
			case 5:
				pageHeading = "Select Language(s)";
				optional = true;
				currentPage =
					<LanguagePage
						nextStep={this.nextStep}
						handleChange={this.handleChangeCheckbox}
						handleChangeValue={this.handleChangeValue}
						values={values}
						getSelectedFacets={this.getSelectedFacets}
						setDynamicFacets={this.handleChangeValue}
						cachedFacets={this.state.cachedLanguageFacets}
					/>;
				break;
			case 6:
					pageHeading = "Select the Categories you're interested in";
					optional = true;
					currentPage =
						<CategoryPage
							nextStep={this.nextStep}
							handleChange={this.handleChangeCheckbox}
							values={values}
						/>;
					break;
			case 7:
				pageHeading = "Select all the Subjects you're interested in";
				optional = true;
				currentPage =
					<SubjectPage
						nextStep={this.nextStep}
						handleChange={this.handleChangeCheckbox}
						handleChangeValue={this.handleChangeValue}
						values={values}
						getSelectedFacets={this.getSelectedFacets}
						setDynamicFacets={this.handleChangeValue}
						cachedFacets={this.state.cachedSubjectFacets}
					/>;
				break;
			case 8:
				pageHeading = "Select all the Genres you're interested in"
				optional = true;
				currentPage =
					<GenrePage
						nextStep={this.nextStep}
						handleChange={this.handleChangeCheckbox}
						handleChangeValue={this.handleChangeValue}
						values={values}
						getSelectedFacets={this.getSelectedFacets}
						setDynamicFacets={this.handleChangeValue}
						cachedFacets={this.state.cachedGenreFacets}
					/>
				break;
			default:
				break;
		}

		return (
			<div className="discoverForm">
				<div className="discoverForm__content">
					<div className="discoverForm__content-header">
						{this.state.isFilterUpdate
							? 	<div onClick={this.renderLastResultsPage}>
									<img src={BookWizardIcon} className="discoverForm__content-header--logo" alt="Book Wizard Logo" tabIndex="0"></img>
									<img src={CloseButtonIcon} className="discoverForm__content-header--close" alt="Close Button" tabIndex="0"></img>
								</div>
							: 	<Link to="/">
									<img src={BookWizardIcon} className="discoverForm__content-header--logo" alt="Book Wizard Logo" tabIndex="0"></img>
									<img src={CloseButtonIcon} className="discoverForm__content-header--close" alt="Close Button" tabIndex="0"></img>
								</Link>
						}
					</div>
					<div className="grid-layout">
						<p className="discoverForm__step-heading">{step} OF 8</p>
						<p className="discoverForm__page-heading">{pageHeading}</p>
						<p className="discoverForm__optional-heading">{optional ? "Optional" : null}</p>
						{currentPage}
					</div>
				</div>
				<div className="discoverForm__footer">
					<div className="discoverForm__footer_buttons__wrapper grid-layout">
						<div className="discoverForm__footer_buttons">
							<OutlineButton
								color="blue"
								text="BACK"
								alt="Back Button"
								className="discoverForm__back-button"
								handleClick={this.prevStep}/>
							<SolidButton
								color="blue"
								text={nextButtonText}
								icon={nextButtonIcon}
								iconLeft
								alt="Next Button"
								disabled={nextDisabled}
								className={`discoverForm__next-button ${nextButtonIcon ? "icon" : ""}`}
								handleClick={this.nextStep}/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(CollectionForm)